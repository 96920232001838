const propertyPrices: Record<string, number> = {
    "Einzelzimmer": 30.00,
    "Doppelzimmer": 32.00,
    "Dreibettzimmer": 34.00,
    "Ferienwohnung": 36.00,
    "Suite": 38.00,
    "DZ Einzelnutzung": 40.00
};

function getPropertyPrice(propertyType: string | null): number {
    if(!propertyType) {
        return 0
    }
    return propertyPrices[propertyType] || 0;
}

export { getPropertyPrice };
