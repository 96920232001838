import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useMemo } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useParams } from 'react-router-dom';
import RowDetails from '../components/data-display/ClientDetailsPanel';
import BookingCalendar from '../components/data-display/reusable/BookingCalendar';
import { useData } from '../contexts/DataContext';
import '../styles/App.css';


dayjs.extend(isBetween);

const RoomDetailsPage: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>(); // Ensure useParams is correctly typed
  const { clients } = useData();
  const clientDetails = clients.find((clients) => `${clients.id}` === clientId);
  const bookingDetails = useMemo(() => {
    return clientDetails ? clientDetails.bookings : [];
  }, [clientDetails]);


  return (
    <div>
      <h2>Client Details Page</h2>
      {clientDetails && <RowDetails row={clientDetails} />}
      <BookingCalendar bookings={bookingDetails}/>
    </div>
  );
};

export default RoomDetailsPage;
