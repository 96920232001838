// DisplayData.ts
import { Invoice } from '../../model/RoomBooking';
import { calculateNumberOfDays } from '../../utils/DateUtils';

export interface DisplayBooking {
    roomId?: string;
    roomType?: string;
    numberOfPeople?: number;
    arrivalDate: string;
    departureDate: string;
    pricePerDay?: number;
    daysCount: number;
    totalAmount: number;
    bun?: number;
    specialEquipment: DisplaySpecialEquipment[];
}

export interface DisplaySpecialEquipment {
    name: string;
    price: number;
    bun?: number;
}

export interface DisplayData {
    totalPrice: number;
    bookings?: DisplayBooking[];
    bruttoSum: number;            // Total gross sum of all bookings
    totalEquipmentPrice: number;  // Total price of all special equipment
    mwst7:number;
    mwst19:number;
    totalBrutto:number;
    netto7:number;
    netto19: number;
    cityTax:number;
    totalNetto:number;
    gesamtbetrag:number;
}

export function createDisplayData(invoice: Invoice): DisplayData {
    const bookings = invoice.bookings?.map(booking => {
        const daysCount = calculateNumberOfDays(booking.startDate, booking.endDate);
        const totalAmount = booking.room ? daysCount * booking.room.price : 0;

        return {
            roomId: booking.room?.id,
            roomType: booking.room?.roomType,
            numberOfPeople: booking.room?.numberOfPeople,
            arrivalDate: booking.startDate,
            departureDate: booking.endDate,
            pricePerDay: booking.room?.price,
            daysCount,
            totalAmount,
            bun: booking.bun,
            specialEquipment: booking.specialEquipment.map(eq => ({
                name: eq.name,
                price: eq.price
            }))
        };
    }) || [];

    // Calculate the total gross sum of all bookings
    const bruttoSum = bookings.reduce((sum, b) => sum + b.totalAmount, 0);

    // Calculate the total price of all special equipment across all bookings
    const totalEquipmentPrice = bookings.reduce((sum, b) => 
        sum + b.specialEquipment.reduce((sumEq, eq) => sumEq + eq.price, 0), 0);

        const mwst7 = bruttoSum*0.07;
        const mwst19 = totalEquipmentPrice*0.19;

    const totalBrutto = bruttoSum+totalEquipmentPrice;

    const netto7 = bruttoSum + mwst7;
    const netto19 = totalEquipmentPrice + mwst19;

    const totalNetto = netto19+netto7

    let cityTax=0;

    if(invoice.bookings && invoice.bookings[0].berechnungsartDerCityTax==='Inklusiv') {
        cityTax = bruttoSum*0.05;
    } else if (invoice.bookings && invoice.bookings[0].berechnungsartDerCityTax==='Zuzüglich') {
        cityTax = totalNetto;
    }

    const gesamtbetrag = totalNetto + cityTax

    return {
        totalPrice: invoice.totalPrice,
        bruttoSum,
        totalEquipmentPrice,
        bookings,
        mwst7,
        mwst19,
        totalBrutto,
        netto19,
        netto7,
        cityTax,
        totalNetto,
        gesamtbetrag
    };
}


