import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import React, { useEffect, useState } from 'react';
import { Client } from '../../model/RoomBooking'; // Adjust import path as needed
import de from '../../resources/locales/local-de';
import { generateFormFields } from '../../utils/formUtils'; // Adjust the path as needed

interface EditRowFormProps {
  row: Client | null;
  open: boolean;
  onClose: () => void;
  onSave: (row: Client) => void; // Function to save the edited or new row
}

export const emptyClient: Client = {
  // Define an empty client object with default values
  id: '', // or a temporary id if necessary
  kdNr: 0,
  bookings: [],
  anrede: '',
  name: '',
  strasse: '',
  wohnort: '',
  land: '',
  email:'',
  geburtstag: '',
  naechsteWerbung: '',
  standortStadt: '',
  objektAnschrift: '',
  benutzername: '',
  telefon: '',
  handy: '',
  // Add other fields as necessary with their default or empty values
};

const EditRowForm: React.FC<EditRowFormProps> = ({ row, open, onClose, onSave }) => {
  const [editedRow, setEditedRow] = useState<Client>(row || emptyClient);

  useEffect(() => {
    setEditedRow(row || emptyClient);
  }, [row, open]);

  // Adjusted handleChange to correctly handle the event from Select component as well
  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    checked?: boolean
  ) => {
    const target = event.target as HTMLInputElement; // Added casting for clearer understanding
    const value = target.type === 'checkbox' ? checked : target.value;
    const name = target.name;
    if (name) { // Ensure that name is defined before attempting to update state
      setEditedRow((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };


  const handleSelectChange: (event: SelectChangeEvent) => void = (event) => {
    const { name, value } = event.target;
    setEditedRow(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(editedRow);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{row ? de.common.edit : de.common.addNew}</DialogTitle>
      <DialogContent>
        {generateFormFields(editedRow, handleChange, handleSelectChange)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{de.common.cancel}</Button>
        <Button onClick={handleSave}>{de.common.save}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRowForm;