import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Box, TextField, Button, Typography, Container } from '@mui/material';
import './LoginPage.css';
import { User } from '../model/User';


const BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface LoginResponse {
    accessToken: string;
    refreshToken: string;
    user: User; 
}

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await axios.post<LoginResponse>(`${BASE_URL}/auth/login`, {
                email,
                password,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                // withCredentials: true // Uncomment if your server expects credentials
            });

            login(response.data.accessToken, response.data.user);
            localStorage.setItem('authTokens', JSON.stringify(response.data));
            setError(null);
            navigate('/');
        } catch (err) {
            setError('Failed to login. Please check your credentials.');
            console.error(err);
        }
    };

    return (
        <Container component="main" className="login-container">
            <Box className="login-box">
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                {error && <Typography color="error" className="error-message">{error}</Typography>}
                <Box component="form" onSubmit={handleLogin} noValidate className="login-form">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-field"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-field"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="login-button"
                    >
                        Login
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
