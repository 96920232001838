import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useData } from '../../contexts/DataContext'; // Adjust the import path if needed
import { Booking, Client, Room, SpecialEquipment } from '../../model/RoomBooking'; // Adjust import path as needed


interface BookingFormProps {
  booking: Booking | null;
  open: boolean;
  onClose: () => void;
  onSave: (booking: Booking) => void;
}



const emptyBooking: Booking = {
  id: '',
  bun: 0,
  room: undefined,
  client: undefined,
  startDate: '',
  endDate: '',
  grundDerBeherbergung: '',
  berechnungsartDerCityTax: '',
  kurTaxeBerechnen: false,
  specialEquipment: []
};

const BookingForm: React.FC<BookingFormProps> = ({ booking, open, onClose, onSave }) => {
  const [editedBooking, setEditedBooking] = useState<Booking>({
    ...emptyBooking,
    specialEquipment: [], // Provide a default empty array
  });
  const { equipment } = useData() as { equipment: SpecialEquipment[] }; // Access equipment from useData
  const { clients, rooms, bookings } = useData();
  const [isFormValid, setIsFormValid] = useState(false);

  const [dateOverlapError, setDateOverlapError] = useState(false);
  const [datesValid, setDatesValid] = useState(true);
  const [cityTaxOption, setCityTaxOption] = useState<string>('');


  useEffect(() => {
    const newBun = bookings.length > 0 ? Math.max(...bookings.map(r => r.bun)) + 1 : 1;
    const initialBooking = booking ? { ...booking } : { ...emptyBooking, bun: newBun };
    setEditedBooking(initialBooking);
  }, [booking, open, clients, rooms, bookings]);

  useEffect(() => {
    // Check if all required fields have values to enable the Save button
    const isValid = editedBooking.room?.roomId !== 0 && editedBooking.client?.kdNr !== 0 && editedBooking.startDate !== '' && editedBooking.endDate !== '' && datesValid;
    console.log(isValid)
    setIsFormValid(isValid);
  }, [editedBooking, datesValid]);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {

    const { name, value, checked } = event.target as { name: string; value: any; checked: boolean };

    let updatedValue = value;

    let idKey: string;
    let idValue: any;


    if (name === 'kdNr') {
      const selectedClient = clients.find((client: Client) => client.kdNr === value);
      idKey = 'client';
      idValue = selectedClient ? selectedClient : undefined;
    } else if (name === 'roomId') {
      const selectedRoom = rooms.find((room: Room) => room.roomId === value);
      idKey = 'room';
      idValue = selectedRoom ? selectedRoom : undefined;
    } else if (name === 'kurTaxeBerechnen') {
      updatedValue = !editedBooking.kurTaxeBerechnen; // Toggle the boolean value
    }

    else if (name === 'specialEquipment') {
      // If checked, add the equipment to the array, otherwise remove it
      updatedValue = checked
        ? [...editedBooking.specialEquipment, equipment.find(e => e.name === value)!]
        : editedBooking.specialEquipment.filter((item) => item.name !== value);
    }

    setEditedBooking((prev) => ({
      ...prev,
      [name]: updatedValue,
      [idKey]: idValue,
      specialEquipment: name === 'specialEquipment' ? updatedValue : prev.specialEquipment,

    }));
  };

  // Function to filter special equipment based on the selected room
  const getAvailableEquipment = () => {
    if (editedBooking.room) {
      return equipment.filter((item) =>
        editedBooking.room?.specialEquipment.some((equipment) => equipment.id === item.id)
      );
    }
    return [];
  };


  const handleSave = () => {
    if (!isFormValid) {
      return;
    }
    onSave(editedBooking);
    onClose();
  };

  // Function to check for overlapping bookings
  const checkForOverlap = (newStartDate: dayjs.Dayjs, newEndDate: dayjs.Dayjs) => {
    const overlap = bookings.some((existingBooking) => {
      if (existingBooking.room?.roomId !== editedBooking.room?.roomId) return false; // Skip if not the same room
      const existingStart = dayjs(existingBooking.startDate);
      const existingEnd = dayjs(existingBooking.endDate);
      return (
        (newStartDate.isSame(existingStart) || newStartDate.isBefore(existingEnd)) &&
        (newEndDate.isSame(existingEnd) || newEndDate.isAfter(existingStart))
      );
    });
    setDateOverlapError(overlap);
    return overlap;
  };

  // Update the handleDateChange function
  const handleDateChange = (name: string, newValue: dayjs.Dayjs | null) => {
    const updatedBooking = { ...editedBooking, [name]: newValue ? newValue.toISOString() : '' };

    // Date validation
    const startDate = updatedBooking.startDate;
    const endDate = updatedBooking.endDate;
    // Check for overlap only if both dates are set
    if (updatedBooking.startDate && updatedBooking.endDate) {
      const start = dayjs(updatedBooking.startDate);
      const end = dayjs(updatedBooking.endDate);
      // Don't allow save if there's an overlap
      if (checkForOverlap(start, end)) {
        setIsFormValid(false);
        setDatesValid(false);
        return; // Prevent further processing if there's an overlap
      }
    }
    const datesAreValid = dayjs(startDate).isValid() && dayjs(endDate).isValid() && !dayjs(endDate).isBefore(dayjs(startDate));

    setDatesValid(datesAreValid);
    setEditedBooking(updatedBooking);
    setIsFormValid(!dateOverlapError && editedBooking.room?.roomId !== 0 && editedBooking.client?.kdNr !== 0);
  };


  useEffect(() => {
    if (editedBooking.grundDerBeherbergung === 'private') {
      setCityTaxOption(editedBooking.berechnungsartDerCityTax);
      console.log(cityTaxOption)
    } else if (editedBooking.grundDerBeherbergung === 'gewerblich') {
      setCityTaxOption('0');
    }
  }, [editedBooking.grundDerBeherbergung, editedBooking.berechnungsartDerCityTax, cityTaxOption]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box>
        <DialogTitle>{booking ? 'Edit Booking' : 'Add New Booking'}</DialogTitle>
      </Box>
      <DialogContent>
        {booking && (<TextField
          margin="dense"
          name="bun"
          label="Booking Number"
          type="number"
          fullWidth
          variant="outlined"
          value={editedBooking.bun}
        />)
        }
        <TextField
          select
          margin="dense"
          label="Client"
          value={editedBooking.client?.kdNr || 0}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          name="kdNr"
          type="number"
          required
        >
          {clients.map((client: Client) => (
            <MenuItem key={client.id} value={client.kdNr}>
              {client.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          margin="dense"
          label="Room"
          value={editedBooking.room?.roomId || 0}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          name="roomId"
          type="number"
          required
        >
          {rooms.map((room: Room) => (
            <MenuItem key={room.id} value={room.roomId}>
              {room.location} - {room.roomType} - {room.numberOfPeople} persons
            </MenuItem>
          ))}
        </TextField>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box margin="dense">
            {dateOverlapError && (
              <Typography variant="body2" color="error" gutterBottom>
                There is a date overlap with another booking.
              </Typography>
            )}
            <DatePicker
              label="Start Date"
              value={editedBooking.startDate ? dayjs(editedBooking.startDate) : null}
              onChange={(newValue) => handleDateChange('startDate', newValue)}
              disablePast
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
            <DatePicker
              label="End Date"
              value={editedBooking.endDate ? dayjs(editedBooking.endDate) : null}
              onChange={(newValue) => handleDateChange('endDate', newValue)}
              minDate={dayjs(editedBooking.startDate)}
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
          </Box>
        </LocalizationProvider>

        <TextField
          select
          margin="dense"
          id="grundDerBeherbergung"
          label="Grund der Beherbergung"
          type="text"
          fullWidth
          name="grundDerBeherbergung"
          value={editedBooking.grundDerBeherbergung}
          onChange={handleChange}
          required
        >
          <MenuItem value="private">Privat</MenuItem>
          <MenuItem value="gewerblich">Gewerblich</MenuItem>
        </TextField>


        {editedBooking.grundDerBeherbergung === 'private' ? (
          <TextField
            select
            margin="dense"
            id="berechnungsartDerCityTax"
            label="Berechnungsart der CityTax"
            type="text"
            fullWidth
            name="berechnungsartDerCityTax"
            value={editedBooking.berechnungsartDerCityTax}
            onChange={handleChange}
            required
          >
            <MenuItem value="Inklusiv">Inklusiv</MenuItem>
            <MenuItem value="Zuzüglich">Zuzüglich</MenuItem>
          </TextField>
        ) : (
          <TextField
            margin="dense"
            id="berechnungsartDerCityTax"
            label="Berechnungsart der CityTax"
            type="text"
            fullWidth
            name="berechnungsartDerCityTax"
            value="0"
            disabled
            required
          />
        )}



        <FormControlLabel
          control={
            <Checkbox
              checked={editedBooking.kurTaxeBerechnen}
              onChange={handleChange}
              name="kurTaxeBerechnen"
            />
          }
          label="Kur Taxe berechnen"
        />

        {/* Special equipment checkboxes */}
        {editedBooking.room && (
          <Box>
            {getAvailableEquipment().map((item: SpecialEquipment) => (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    checked={editedBooking.specialEquipment?.some((e) => e.id === item.id)}
                    onChange={handleChange}
                    name="specialEquipment"
                    value={item.name}
                  />
                }
                label={item.name}
              />
            ))}
          </Box>
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={!isFormValid || !datesValid}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingForm;
