import { Chip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Booking } from '../../model/RoomBooking';
import de from '../../resources/locales/local-de';
import { calculateNumberOfDays } from '../../utils/DateUtils';

interface BookingDetailsProps {
  row: Booking | null;
}

const RowDetails: React.FC<BookingDetailsProps> = ({ row }) => {
  const navigate = useNavigate();

  const handleBookingDetailsClick = () => {
    if (row) {
      navigate(`/booking/${row.id}`);
    }
  };


  if (!row || !row.room) {
    return <div>{de.common.noBookingsFound}</div>;
  }

  const numberOfDays = calculateNumberOfDays(row.startDate, row.endDate)
  const bruttoSumme = row.room.price * numberOfDays;
  const sumEquipmentPrices = row.specialEquipment.reduce((total, item) => total + item.price, 0);
  const Mwst19 = (sumEquipmentPrices * 0.19).toFixed(2)
  // let cityTax ;
  // if(row.berechnungsartDerCityTax=="Inklusiv"){
  //   cityTax= 
  // }
  return (
    <div>
      <h3 style={{ cursor: 'pointer' }} onClick={handleBookingDetailsClick}>{de.detailsPanel.bookingDetails}</h3>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
        <div>
          <p>{de.common.bookingNumber} : {row.bun}</p>
          <p>{de.common.startDate} : {row.startDate}</p>
          <p>{de.common.endDate} : {row.endDate}</p>
          <p>{de.detailsPanel.reasonForAccommodation} : {row.grundDerBeherbergung}</p>
          <p>{de.detailsPanel.calculationMethodCityTax} : {row.berechnungsartDerCityTax}</p>
          <p>{de.bookingForm.calculateVisitorsTax} : {row.kurTaxeBerechnen ? de.common.yes : de.common.no}</p>
          <p>Anzahl Tage : {numberOfDays}</p>
          <p>Preis pro Zimmer und Tag : {row.room.price}</p>
          <p>Brutto Summe : {row.room.price * numberOfDays} €</p>
          <p>Mwst. 7% : {(bruttoSumme * 0.07).toFixed(2)}</p>
          {row.specialEquipment &&
            <div>
              <p>Gesonderte Ausstattung:
                <div>
                  <p>{de.detailsPanel.specialEquipment}:</p>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {row.specialEquipment.map((equipment) => (
                      <Chip key={equipment.id} label={`${equipment.name}  ${equipment.price}€`}
                        style={{ margin: '2px' }} />
                    ))}
                  </div>
                </div>
              </p>
              <p>Preis Gesonderte Ausstattung: {sumEquipmentPrices}€</p>
              <p>Mwst. 19% : {Mwst19}€</p>
            </div>
          }
          {row.grundDerBeherbergung === "private" &&
            <p>{row.berechnungsartDerCityTax} City Tax : 5%</p>
          }
          {row.kurTaxeBerechnen &&
            <p>Kur Tax : 1.50€</p>
          }
        </div>
      </div>
    </div>
  );
};

export default RowDetails;
