import { GridColDef } from '@mui/x-data-grid';


// Function to generate columns specific for rooms
export const bookingColumns: GridColDef[] = [
  { field: 'bun', headerName: 'BUN', width: 130 },
  {
    field: 'roomId', headerName: 'Room Id', width: 150,
    valueGetter: (params) => {
      return params.row.room ? params.row.room.roomId : params.row.roomId}
  },
  {
    field: 'kdNr', headerName: 'Kd. Nr', width: 150,
    valueGetter: (params) => {
      return params.row.client ? params.row.client.kdNr : params.row.kdNr
    }
  },
  { field: 'grundDerBeherbergung', headerName: 'Grund der Beherbergung', width: 150 },
  { field: 'berechnungsartDerCityTax', headerName: 'Berechnungsart der CityTax', width: 150 },
  { field: 'kurTaxeBerechnen', headerName: 'kur Taxe Berechnen', width: 150 },
];


