import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; // Adjust the path based on where your AuthContext is
import './Navbar.css'; // Ensure your CSS file path is correct

const Navbar: React.FC = () => {


  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Log the user out
    navigate('/login'); // Redirect to the login page
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          My App
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button color="inherit" component={Link} to="/">Home</Button>
          <Button color="inherit" component={Link} to="/uebersichtstafel">Übersichtstafel</Button>
          <Button color="inherit" component={Link} to="/umsatzliste">Umsatzliste</Button>
          <Button color="inherit" component={Link} to="/belegungsplan">Belegungsplan</Button>
          <Button color="inherit" component={Link} to="/buchungen">Buchungen</Button>
          {isAuthenticated ? (
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          ) : (
            <>
              <Button color="inherit" component={Link} to="/login">Login</Button>
              <Button color="inherit" component={Link} to="/register">Register</Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
