import React from 'react';
import { Invoice } from '../../model/RoomBooking'; // Adjust import path as needed
import de from '../../resources/locales/local-de';
import BookingTable from './BookingTable';

interface InvoiceDetailsProps {
  invoice: Invoice | null;
}

const InvoiceDetailsPanel: React.FC<InvoiceDetailsProps> = ({ invoice }) => {

  if (!(invoice && invoice.bookings && invoice.bookings.length > 0)) {
    return <div>{de.common.noInvoicesFound}</div>;
  }

  const booking = invoice.bookings[0];

  if (!(booking && booking.client)) {
    return <div>No booking details found</div>;
  }

  // const date = new Date();

  // // Get the day of the month (1-31)
  // const dayOfMonth = date.getDate();

  // // Get the month (0-11, where 0 is January)
  // const month = date.getMonth() + 1; // Adding 1 because months are zero-based

  // // Get the year (4 digits)
  // const year = date.getFullYear();

  return (
    <div>
      <h3>{de.invoiceDetails.title}</h3>
      <BookingTable invoice={invoice}/>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
        <div>
          <p>{de.clientDetails.name}: {booking.client.name}</p>
          <p>{de.clientDetails.street}: {booking.client.strasse}</p>
          <p>{de.clientDetails.residence}: {booking.client.wohnort}</p>
          <p>{de.clientDetails.customerNumber}: {invoice.invoiceId}</p>
        </div>
      </div>
    </div>
  );

};

export default InvoiceDetailsPanel;
