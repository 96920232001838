import { emptyClient } from '../../components/forms/ClientForm';
import { Booking, Invoice } from "../RoomBooking";
import { CustomerDTO, clientToCustomerDTO, emptyCustomer } from './CustomerDTO';


export interface InvoiceDTO {
  invoiceId?: number ;
  date: string;
  userId: string;
  customerDTO: CustomerDTO;
  bookingIds: string[];
  price?: number;
}

export const invoiceDTOToInvoice = (dto: InvoiceDTO) : Invoice => {

  let invoice: Invoice = {
    invoiceId: dto.invoiceId ? dto.invoiceId : -1, 
    bookings: [],
    date:'',
    user: null,
    beneficiaryName: '',
    beneficiaryAddress: '',
    beneficiaryCUI: '',
    totalPrice: 0,
    createdDate: dto.date
  }

  return invoice
}

// Transform Invoice DTO to Invoice Model
export const transformInvoiceDTOToInvoice = (invoiceDTO: InvoiceDTO, bookings: Booking[]): Invoice => ({
  invoiceId: invoiceDTO.invoiceId ? invoiceDTO.invoiceId : -1,
  date: new Date().toISOString(),
  user: null, // Assuming no user info in DTO
  beneficiaryName: "Default Name",
  beneficiaryAddress: "Default Address",
  beneficiaryCUI: "Default CUI",
  bookings: bookings.filter(booking => invoiceDTO.bookingIds.includes(booking.id)),
  totalPrice: invoiceDTO.price ? invoiceDTO.price : 0,
  createdDate: new Date().toISOString()
});


/**
 * Transforms an Invoice model into an InvoiceDTO for backend communication.
 * @param invoice The Invoice object from the frontend.
 * @returns The transformed InvoiceDTO object.
 */
export const transformInvoiceToInvoiceDTO = (invoice: Invoice): InvoiceDTO => {
  return {
      invoiceId: invoice.invoiceId,
      date: invoice.date,
      userId: invoice.user ? invoice.user.id : '',  // Assuming the user object has an 'id' field
      bookingIds: invoice.bookings?.map(booking => booking.id) || [],  // Convert booking objects to their IDs
      price: invoice.totalPrice,
      customerDTO: invoice.bookings ? clientToCustomerDTO(invoice.bookings[0].client ? invoice.bookings[0].client : emptyClient) : emptyCustomer
  };
};
