import { Button, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import RowDetails from '../components/data-display/BookingDetailsPanel';
import DateRangeGrid from '../components/data-display/reusable/DateRangeGrid';
import BookingForm from '../components/forms/BookingForm';
import { useData } from '../contexts/DataContext';
import { Booking } from '../model/RoomBooking';
import de from '../resources/locales/local-de';
import './BuchungenPage.css';

const BuchungenPage: React.FC = () => {
    const {bookings, addEntity, updateEntity } = useData(); // Adjust based on your context methods
    const [rows, setRows] = useState<Booking[]>(bookings);
    const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(dayjs());
    const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs().add(1, 'month'));
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<Booking | null>(null);

    useEffect(() => {
        console.log(bookings)
        setRows(bookings);
    }, [bookings]);

    const handleNewBooking = () => {
        setSelectedRow(null);
        setIsEditModalOpen(true);
    };

    const handleRowClick = (params: any) => {
        setSelectedRow(params.row);
    };

    const handleRowDoubleClick = (params: any) => {
        console.log(params)
        // navigate(`/booking/${params.booking.id}`);
    };

    const handleSaveEditedRoom = (editedRow: Booking) => {
        if (editedRow.id === '') {
            setRows([...rows, { ...editedRow }]);
            addEntity('booking', editedRow);
        } else {
            updateEntity('booking',editedRow);
            setRows(rows.map(row => (row.id === editedRow.id ? editedRow : row)));
        }
        handleEditClose();
    };

    const handleEditClose = () => {
        setIsEditModalOpen(false);
        setSelectedRow(null); // Reset selectedRoom when closing the form
    };

    return (
        <div className="pageContainer">
            <div className="datePickerContainer">
                <Grid item>
                    <Button variant="contained" onClick={handleNewBooking} className="addButton">
                        {de.bookingForm.titleNew}
                    </Button>
                </Grid>
                <Grid item container className="datePickersContainer">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DatePicker
                            label={de.common.startDate}
                            value={startDate}
                            defaultValue={startDate ? dayjs(startDate) : null}
                            onChange={(newStartDate) => setStartDate(newStartDate)}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DatePicker
                            label={de.common.endDate}
                            value={endDate}
                            defaultValue={endDate ? dayjs(endDate) : null}
                            onChange={(newEndDate) => setEndDate(newEndDate)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item className="dataGridContainer">
                    <DateRangeGrid
                        startDate={startDate?.toDate() || new Date()}
                        endDate={endDate?.toDate() || new Date()}
                        data={bookings}
                        dataType='booking'
                        handleRowClick={handleRowClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                    />
                </Grid>
            </div>
            {selectedRow && (
                <RowDetails row={selectedRow} />
            )}
            {isEditModalOpen && (
                <BookingForm
                    booking={selectedRow}
                    open={isEditModalOpen}
                    onSave={handleSaveEditedRoom}
                    onClose={handleEditClose}
                />
            )}
        </div> 
    );
};

export default BuchungenPage;
