import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import './Footer.css'; // Import your CSS file here

const Footer: React.FC = () => {
  return (
    <Box component="footer" className="footer">
      <Container maxWidth="lg">
        <Typography variant="body1">
          My Sticky Footer can be found here.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
