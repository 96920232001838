import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Booking, Room } from '../../../model/RoomBooking';
import { bookingColumns } from '../BookingColumns';
import { roomColumns } from '../RoomColumns';
import PinnedColumnsGrid from './PinnedColumnsGrid';


interface DateRangeGridProps {
  startDate: Date | null;
  endDate: Date | null;
  data: Room[] | Booking[];
  dataType: 'room' | 'booking';
  handleRowClick: (params: any) => void;
  handleRowDoubleClick: (params: any) => void;
}

const DateRangeGrid: React.FC<DateRangeGridProps> = ({ startDate, endDate, data, dataType, handleRowClick, handleRowDoubleClick }) => {

    // Log each booking's start and end date
    useEffect(() => {
      console.log(data);
  }, [data]); // Re-run this effect if the bookings array changes

  const generateDateColumns = (startDate: Date, endDate: Date): GridColDef[] => {
    const dateColumns: GridColDef[] = [];
    let currentDate = dayjs(startDate);
    const lastDate = dayjs(endDate);

    while (currentDate.isBefore(lastDate, 'day') || currentDate.isSame(lastDate, 'day')) {
      const dateString = currentDate.format('YYYY-MM-DD'); // Adjusted for consistency
      dateColumns.push({
        field: dateString,
        headerName: dateString,
        width: 100,
        renderCell: (params) => {
          let isBooked = false;

          if (dataType === 'room') {
            // Check if any booking for this room includes the cell's date
            isBooked = params.row.bookings?.some((booking: Booking) => 
            {
              const startDay = dayjs(booking.startDate);
              const endDay = dayjs(booking.endDate);
              const currentDay = dayjs(dateString);
              
              return currentDay.isBetween(startDay, endDay, null, '[]') ||
                     currentDay.isSame(startDay, 'day') ||
                     currentDay.isSame(endDay, 'day');
            }
            );
          } else if (dataType === 'booking') {
            // Since this is a booking, check if the cell's date is within the booking's start and end dates
            const startDate = dayjs(params.row.startDate);
            const endDate = dayjs(params.row.endDate);
            isBooked = dayjs(dateString).isBetween(startDate, endDate, null, '[]') ||
                       dayjs(dateString).isSame(startDate, 'day') ||
                       dayjs(dateString).isSame(endDate, 'day');
          }

          return <div style={{ backgroundColor: isBooked ? 'yellow' : 'transparent', width: '100%', height: '100%' }} />;
        },
      });

      currentDate = currentDate.add(1, 'day');
    }

    return dateColumns;
  };

  const baseColumns: GridColDef[] = dataType === 'room' ? roomColumns : bookingColumns;
  const dateColumns = generateDateColumns(startDate || new Date(), endDate || new Date());


  return (
    <PinnedColumnsGrid 
      rows={data}
      pinnedColumns={baseColumns}
      otherColumns={dateColumns}
      onRowClick={handleRowClick}
      onRowDoubleClick={handleRowDoubleClick}
    />
  );
};

export default DateRangeGrid;
