import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import { useParams } from 'react-router-dom';
import DetailsPanel from '../components/data-display/RoomDetailsPanel';
import BookingCalendar from '../components/data-display/reusable/BookingCalendar';
import { useData } from '../contexts/DataContext';
import '../styles/App.css';


dayjs.extend(isBetween);

const RoomDetailsPage: React.FC = () => {
    const { roomId } = useParams<{ roomId: string }>(); // Ensure useParams is correctly typed
    const { rooms } = useData();
    const roomDetails = rooms.find((room) => `${room.id}` === roomId);
    const bookingDetails = roomDetails ? roomDetails.bookings : [];

    return (
        <div>
            <h2>Room Details Page</h2>
            {roomDetails && <DetailsPanel roomDetails={roomDetails} bookingDetails={bookingDetails} />}
            <BookingCalendar bookings={bookingDetails} />
        </div>
    );
};

export default RoomDetailsPage;
