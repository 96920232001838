import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Invoice } from '../../model/RoomBooking'; // Adjust import path as needed
import de from '../../resources/locales/local-de';
import dayjs from 'dayjs';


interface InvoiceFormProps {
  invoice: Invoice | null;
  open: boolean;
  onClose: () => void;
  onSave: (invoice: Invoice) => void; // Function to save the edited or new invoice
}

const emptyInvoice: Invoice = {
  invoiceId: -1, // Generate or assign a unique ID for the invoice
  bookings: [],
  date:'',
  user: null,
  beneficiaryName: 'Mustermann',
  beneficiaryAddress: 'WeberStraße 66, 45772 Marl, Deutschland',
  beneficiaryCUI: '',
  totalPrice: 0,
  createdDate: dayjs().toString()
};

const InvoiceForm: React.FC<InvoiceFormProps> = ({ invoice, open, onClose, onSave }) => {
  const [editedInvoice, setEditedInvoice] = useState<Invoice>(invoice || emptyInvoice);

  useEffect(() => {
    setEditedInvoice(invoice || emptyInvoice);
  }, [invoice, open]);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // If the field is kaufdatum, convert the value to a dayjs object
    const newValue = name === 'kaufdatum' ? dayjs(value) : value;
    setEditedInvoice(prev => ({ ...prev, [name]: newValue }));
  };

  const handleSave = () => {
    onSave(editedInvoice);
    onClose();
  };


  if (!invoice || !invoice.bookings || invoice.bookings.length === 0) {
    return <div>{de.common.noInvoicesFound}</div>;
  }

  const booking = invoice.bookings[0];
  
  if (!booking || !booking.client) {
    return <div>No booking details found</div>;
  }

  // Generate form fields dynamically or manually map them as per your setup
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{invoice ? de.common.edit : de.common.addNew}</DialogTitle>
      <DialogContent>
      <TextField
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          name="name"
          value={booking.client.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Straße"
          type="text"
          fullWidth
          name="strasse"
          value={booking.client.strasse}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Wohnort"
          type="text"
          fullWidth
          name="wohnort"
          value={booking.client.wohnort}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Rechnungsnummer"
          type="text"
          fullWidth
          name="reNr"
          // value={editedInvoice.reNr}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Jahr"
          type="number"
          fullWidth
          name="jahr"
          // value={editedInvoice.jahr}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Monat"
          type="number"
          fullWidth
          name="monat"
          // value={editedInvoice.monat}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Tag"
          type="number"
          fullWidth
          name="tag"
          // value={editedInvoice.tag}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Kosten"
          type="number"
          fullWidth
          name="kosten"
          value={editedInvoice.totalPrice}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{de.common.cancel}</Button>
        <Button onClick={handleSave}>{de.common.save}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceForm;
