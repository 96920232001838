import { findAssociatedBookings, findAssociatedEquipment } from '../../contexts/ContextUtils';
import { Booking, Room, SpecialEquipment } from '../../model/RoomBooking'; 

export interface PropertyDTO {
    id?: string; // UUID string format
    roomId?: number;
    address: string;
    location: string;
    nextCleaning?: string;
    nextGasDelivery?: string;
    nextMattressChange?: string;
    numberOfPeople: number;
    price: number;
    roomType: string;
    specialEquipmentIds: string[];
    customerIds: string[]; // Assuming this field might be necessary
    bookingIds: string[];
    ownerId?: string; // Assuming this field might be necessary
}


export function getSpecialEquipmentNames(room: Room): string[] {
    return room.specialEquipment.map((equipment: SpecialEquipment) => equipment.name);
  }

  export function getSpecialEquipmentIds(room: Room): string[] {
    return room.specialEquipment.map((equipment: SpecialEquipment) => equipment.id);
  }

export const roomToPropertyDTO = (room: Room): PropertyDTO => {
    const propertyDTO: PropertyDTO = {
        id: room.id?.toString(),
        roomId: room.roomId,
        address: room.address,
        location: room.location,
        nextCleaning: room.nextCleaning,
        nextGasDelivery: room.nextGasDelivery,
        nextMattressChange: room.nextMattressChange,
        numberOfPeople: room.numberOfPeople,
        price: room.price,
        roomType: room.roomType,
        specialEquipmentIds: getSpecialEquipmentIds(room),
        customerIds: [], 
        bookingIds: room.bookings?.map(booking => booking.id) || [], 
        ownerId: room.ownerId?.toString(),
    };

    return propertyDTO;
};


export const propertyDTOtoRoom = (dto: PropertyDTO, specialEquipment: SpecialEquipment[], bookings: Booking[]): Room => {
  const room: Room = {
      id: dto.id? dto.id : '',
      roomId: dto.roomId ? dto.roomId : 0,
      address: dto.address,
      location: dto.location,
      nextCleaning: dto.nextCleaning ? dto.nextCleaning : '',
      nextGasDelivery: dto.nextGasDelivery ? dto.nextGasDelivery : '',
      nextMattressChange: dto.nextMattressChange ? dto.nextMattressChange : '',
      numberOfPeople: dto.numberOfPeople,
      price: dto.price,
      roomType: dto.roomType,
      specialEquipment: findAssociatedEquipment(dto.specialEquipmentIds, specialEquipment),
      bookings: findAssociatedBookings(dto.bookingIds, bookings),
      ownerId: dto.ownerId ? dto.ownerId : '',
  };

  return room;
};


// Transformation utility to enrich DTOs with model data
export const transformRoomDTOToRoom = (dto: PropertyDTO, equipments: SpecialEquipment[], bookings: Booking[]): Room => ({
  id: dto.id ? dto.id : '',
  roomId: dto.roomId ? dto.roomId : -1,
  location: dto.location, // Default or fetch if necessary
  address: dto.address, // Default or fetch if necessary
  roomType: dto.roomType,
  numberOfPeople: dto.numberOfPeople,
  nextCleaning: dto.nextCleaning ? dto.nextCleaning : '', // Set default or fetch
  nextMattressChange: dto.nextMattressChange ? dto.nextMattressChange : '', // Set default or fetch
  nextGasDelivery: dto.nextGasDelivery ? dto.nextGasDelivery : '', // Set default or fetch
  price: dto.price,
  specialEquipment: equipments.filter(eq => dto.specialEquipmentIds.includes(eq.id)),
  bookings: bookings.filter(b => dto.bookingIds.includes(b.id)), // Populate later if needed
  ownerId: "" // Set default or fetch
});


