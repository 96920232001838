// BookingTable.tsx
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Invoice } from '../../model/RoomBooking';
import { DisplayData, createDisplayData } from './DataDisplay';

interface MyTableProps {
    invoice: Invoice;
}

const BookingTable: React.FC<MyTableProps> = ({ invoice }) => {
    const displayData: DisplayData = createDisplayData(invoice);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="invoice table">
                <TableHead>
                    <TableRow>
                        <TableCell>Anzahl Zimmer</TableCell>
                        <TableCell>Zimmerart</TableCell>
                        <TableCell>Personen pro Zimmer</TableCell>
                        <TableCell>Tag der Anreise</TableCell>
                        <TableCell>Tag der Abreise</TableCell>
                        <TableCell>Preis pro Zimmer und Tag</TableCell>
                        <TableCell>Anzahl Tage</TableCell>
                        <TableCell>Gesamtbetrag</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayData.bookings && displayData.bookings.map((booking, index) => (
                        <TableRow key={index}>
                            <TableCell>1</TableCell>
                            <TableCell>{booking.roomType}</TableCell>
                            <TableCell>{booking.numberOfPeople}</TableCell>
                            <TableCell>{booking.arrivalDate}</TableCell>
                            <TableCell>{booking.departureDate}</TableCell>
                            <TableCell>{booking.pricePerDay}</TableCell>
                            <TableCell>{booking.daysCount}</TableCell>
                            <TableCell>{booking.totalAmount.toFixed(2)}€</TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>Übernachtungskosten</TableCell>
                        <TableCell>{displayData.bruttoSum} €</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>MwSt. 7%</TableCell>
                        <TableCell>{displayData.mwst7.toFixed(2)} €</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>Netto 7%</TableCell>
                        <TableCell>{displayData.netto7.toFixed(2)} €</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3} style={{ fontWeight: 'bold' }}>ZusatzBerechnung</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>BUN</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Price</TableCell>
                    </TableRow>
                    {displayData.bookings?.map((booking, bookingIndex) =>
                        booking.specialEquipment.map((eq, equipmentIndex) => (
                            <TableRow key={`equipment-${bookingIndex}-${equipmentIndex}`}>
                                <TableCell>{booking.bun}</TableCell>
                                <TableCell>{eq.name}</TableCell>
                                <TableCell>{eq.price} €</TableCell>
                            </TableRow>
                        ))
                    )}
                    <TableRow>
                        <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>Total ZusatzBerechnung</TableCell>
                        <TableCell>{displayData.totalEquipmentPrice.toFixed(2)} €</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>Gesammt brutto Summe</TableCell>
                        <TableCell>{displayData.totalBrutto.toFixed(2)} €</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>MwSt. 19%</TableCell>
                        <TableCell>{displayData.mwst19.toFixed(2)} €</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>Netto 19%</TableCell>
                        <TableCell>{displayData.netto19.toFixed(2)} €</TableCell>
                    </TableRow>
                    {invoice.bookings && invoice.bookings[0].grundDerBeherbergung === "private" &&
                        <TableRow>
                            <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>City Tax :</TableCell>
                            <TableCell>5%</TableCell>
                        </TableRow>
                    }
                    {invoice.bookings && invoice.bookings[0].grundDerBeherbergung === "private" &&
                        <TableRow>
                            <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>City Tax calculation: </TableCell>
                            <TableCell>{invoice.bookings[0].berechnungsartDerCityTax}</TableCell>
                        </TableRow>
                    }

                    {invoice.bookings && invoice.bookings[0].grundDerBeherbergung === "gewerblich" &&
                        <TableRow>
                            <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>City Tax : </TableCell>
                            <TableCell>wird nicht berechnet für Unternehmen</TableCell>
                        </TableRow>

                    }

                    {invoice.bookings && invoice.bookings[0].grundDerBeherbergung === "private" &&
                        <TableRow>
                            <TableCell colSpan={7} style={{ fontWeight: 'bold' }}>City Tax : </TableCell>
                            <TableCell>{displayData.cityTax}€</TableCell>
                        </TableRow>
                    }

                    {invoice.bookings &&
                        <TableRow>
                            <TableCell colSpan={6} style={{ fontWeight: 'bold' }}>Kur Tax berechnen : </TableCell>
                            <TableCell>{invoice.bookings[0].kurTaxeBerechnen ? 'ja ' : ''}</TableCell>
                            <TableCell>{invoice.bookings[0].kurTaxeBerechnen ? '1.50€ ' : 'nein'}</TableCell>
                        </TableRow>
                    }

                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell colSpan={7} style={{ fontWeight: 'bold'}}>Rechnungsbetrag</TableCell>
                        <TableCell style={{ fontWeight: 'bold'}}>{displayData.gesamtbetrag.toFixed(2)} €</TableCell>
                    </TableRow>


                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default BookingTable;
