import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import { Client } from '../model/RoomBooking';
import de from '../resources/locales/local-de';

interface Field {
  name: keyof Client;
  label: string;
  type: 'text' | 'date' | 'checkbox' | 'select';
  options?: { value: string; label: string }[];
}

export const generateFormFields = (
  row: Client,
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown; }>, checked?: boolean) => void,
  handleSelectChange?: (event: SelectChangeEvent) => void // Add an optional select change handler
): JSX.Element[] => {
  const fields: Field[] = [
    {
      name: 'anrede',
      label: de.formLabels.salutation,
      type: 'select',
      options: [
        { value: 'Herr', label: 'Herr' },
        { value: 'Frau', label: 'Frau' },
        { value: 'Divers', label: 'Divers' }
      ],
    },
    { name: 'name', label: de.formLabels.name, type: 'text' },
    { name: 'strasse', label: de.formLabels.street, type: 'text' },
    { name: 'wohnort', label: de.formLabels.residence, type: 'text' },
    { name: 'land', label: de.formLabels.country, type: 'text' },
    { name: 'geburtstag', label: de.formLabels.birthday, type: 'date' },
    { name: 'naechsteWerbung', label: de.formLabels.nextAdvertisement, type: 'text' },
    { name: 'standortStadt', label: de.formLabels.locationCity, type: 'text' },
    { name: 'objektAnschrift', label: de.formLabels.objectAddress, type: 'text' },
    { name: 'benutzername', label: de.formLabels.username, type: 'text' },
    { name: 'telefon', label: de.formLabels.phone, type: 'text' },
    { name: 'handy', label: de.formLabels.mobile, type: 'text' },
  ];

  return fields.map((field) => {
    if (field.type === 'checkbox') {
      return (
        <FormControlLabel
          key={field.name}
          control={
            <Checkbox
              checked={Boolean(row[field.name])}
              onChange={(event) => handleChange(event, event.target.checked)}
              name={field.name}
            />
          }
          label={field.label}
        />
      );
    } else if (field.type === 'select') {
      return (
        <FormControl fullWidth margin="dense" key={field.name}>
          <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
          <Select
            labelId={`${field.name}-label`}
            id={field.name}
            value={row[field.name] as string}
            label={field.label}
            onChange={(event) => handleSelectChange ? handleSelectChange(event) : null} // Use the select-specific handler if provided
            name={field.name}
          >
            {field.options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <TextField
          key={field.name}
          margin="dense"
          name={field.name}
          label={field.label}
          type={field.type === 'date' ? 'date' : 'text'}
          fullWidth
          variant="outlined"
          value={row[field.name] as string} // Ensure correct typing for value
          onChange={handleChange}
          InputLabelProps={field.type === 'date' ? { shrink: true } : undefined}
        />
      );
    }
  });
};
