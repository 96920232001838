import { Invoice } from "../model/RoomBooking";
import { calculateNumberOfDays } from "./DateUtils";


export function calculateBruttoSumme(invoice: Invoice): number {

    if (!invoice.bookings){
        return 0;
    }

    const totalRoomPrices = invoice.bookings.reduce((total, booking) => {
        if(!booking.room){
            return 0
        }
        return total + (booking.room.price*calculateNumberOfDays(booking.startDate, booking.endDate))
    }, 0)


    return totalRoomPrices
}

export function calculateSumEquipmentPrices(invoice: Invoice): number {
    if (!invoice.bookings){
        return 0;
    }

    const totalRoomsEqPrices = invoice.bookings.reduce((total, booking) => {
        if(!booking.room?.specialEquipment){
            return 0
        }

        const roomEqPrice = booking.room.specialEquipment.reduce((total, eq) => 
            total+eq.price , 0
        )
        return total + roomEqPrice
    }, 0)
    return totalRoomsEqPrices
}

export function calculateMwst19(invoice: Invoice): string {
    return (calculateSumEquipmentPrices(invoice) * 0.19).toFixed(2)
}

export function calculateMwst7(invoice: Invoice): string {
    return (calculateBruttoSumme(invoice)*0.07).toFixed(2)
}


// let cityTax ;
// if(row.berechnungsartDerCityTax=="Inklusiv"){
//   cityTax= 
// }