// src/locales/de.js
const de = {
    common: {
        save: "Speichern",
        cancel: "Abbrechen",
        yes: "Ja",
        no: "Nein",
        addNew: "Neu hinzufügen",
        edit: "Bearbeiten",
        startDate: "Startdatum",
        endDate: "Enddatum",
        bookingNumber: "Buchungsnummer",
        client: "Kunde",
        room: "Zimmer",
        noBookingsFound: "Keine Buchungen gefunden",
        noClientsFound: "Keine Kunden gefunden",
        details: "Details",
        bookings: "Buchungen",
        noInvoicesFound: "Keine Quittungen gefunden",
    },
    bookingForm: {
        titleNew: "Neue Buchung hinzufügen",
        titleEdit: "Buchung bearbeiten",
        reasonForStay: "Grund des Aufenthalts",
        cityTaxCalculation: "Berechnung der Kurtaxe",
        calculateVisitorsTax: "Kurtaxe berechnen?",
    },
    clientDetails: {
        title: "Kundendetails",
        customerNumber: "Kd.Nr.",
        salutation: "Anrede",
        name: "Name",
        street: "Straße",
        residence: "Wohnort",
        country: "Land",
        birthday: "Geburtstag",
        nextAdvertisement: "Nächste Werbung",
        locationCity: "Standort / Stadt",
        objectAddress: "Objekt / Anschrift",
        username: "Benutzername",
        phone: "Telefon",
        mobile: "Handy",
    },
    roomOccupancy: {
        addNewRoom: "Neues Zimmer hinzufügen",
    },
    detailsPanel: {
        roomDetails: "Zimmerdetails",
        bookingDetails: "Buchungsdetails",
        location: "Standort",
        address: "Adresse",
        roomType: "Zimmertyp",
        numberOfPeople: "Personenanzahl",
        nextCleaning: "Nächste Reinigung",
        nextMattressChange: "Nächster Matratzenwechsel",
        nextGasDelivery: "Nächste Gaslieferung",
        price: "Preis",
        specialEquipment: "Sonderausstattung",
        noSpecialEquipment: "Keine Sonderausstattung",
        reasonForAccommodation: "Grund der Beherbergung",
        calculationMethodCityTax: "Berechnungsart der City-Tax",
        calculationCityTax:  "Kurtaxe berechnen?",
    },
    formLabels: {
        salutation: "Anrede",
        salutations: {
            mr: "Herr",
            mrs: "Frau",
            other: "Andere",
        },
        name: "Name",
        street: "Straße",
        residence: "Wohnort",
        country: "Land",
        birthday: "Geburtstag",
        nextAdvertisement: "Nächste Werbung",
        locationCity: "Standort/Stadt",
        objectAddress: "Objekt/Anschrift",
        username: "Benutzername",
        phone: "Telefon",
        mobile: "Handy",
    },
    invoiceDetails: {
        title: "Quittungsdetails",
        year: "Jahr",
        month: "Monat",
        day: "Tag",
        netAmount: "Nettobetrag",
        vat7: "7% MwSt",
        vat19: "19% MwSt",
        reasonForAccommodation: "Grund der Beherbergung",
        cityTax: "City Tax",
        visitorsTax: "Kur Tax",
        revenueWithTax: "Umsatz mit Steuer",
        invoiceNumber: "Beleg Nr",
        purchaseDate: "Kaufdatum",
        net: "Netto",
        vat: "MwSt",
        costs: "Kosten"
      },

};

export default de;
