import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useAuth } from './AuthContext';
import { useFetch } from '../components/hooks/useFetch';
import BookingController from '../controllers/BookingController';
import ClientController from '../controllers/ClientController';
import RoomController from '../controllers/RoomController';
import InvoiceController from '../controllers/InvoiceController';
import { Client, Room, Booking, SpecialEquipment, Invoice } from '../model/RoomBooking'; // Adjust import paths
import { roomToPropertyDTO, transformRoomDTOToRoom } from '../model/dto/PropertyDTO';
import { mapBookingToBookingDTO, transformBookingDTOToBooking } from '../model/dto/BookingDTO';
import { transformInvoiceDTOToInvoice, transformInvoiceToInvoiceDTO } from '../model/dto/InvoiceDTO';
import dayjs from 'dayjs';
import { clientToCustomerDTO } from '../model/dto/CustomerDTO';
import { emptyClient } from '../components/forms/ClientForm';

interface DataContextType {
    clients: Client[];
    rooms: Room[];
    bookings: Booking[];
    equipment: SpecialEquipment[];
    invoices: Invoice[];
    addEntity: (type: string, entity: any) => Promise<void>;
    updateEntity: (type: string, entity: any) => Promise<void>;
    deleteEntity: (type: string, id: string) => Promise<void>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

interface DataProviderProps {
    children: ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
    const { user, isAuthenticated } = useAuth();
    const { data, fetchData } = useFetch();
    const [clients, setClients] = useState<Client[]>([]);
    const [rooms, setRooms] = useState<Room[]>([]);
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [equipment, setEquipment] = useState<SpecialEquipment[]>([]);
    const [invoices, setInvoices] = useState<Invoice[]>([]);

    useEffect(() => {
        if (user && isAuthenticated) {
            fetchData(user, isAuthenticated).catch(console.error);
        }
    }, [user, isAuthenticated, fetchData]);

    useEffect(() => {
        setClients(data.clients);
        setRooms(data.rooms);
        setBookings(data.bookings);
        setEquipment(data.equipment);
        setInvoices(data.invoices);
    }, [data]);

    const addEntity = async (type: string, entity: any) => {
        switch (type) {
            case 'client':
                const newClient = await ClientController.addClient(entity);
                setClients(prev => [...prev, newClient]);
                break;
            case 'room':
                const dto = roomToPropertyDTO(entity)
                const roomDTO = await RoomController.addProperty(dto);
                const newRoom = transformRoomDTOToRoom(roomDTO, equipment, []);
                setRooms(prev => [...prev, newRoom]);
                const updatedEquipment = equipment.map(eq => {
                    if (newRoom.specialEquipment.some(se => se.id === eq.id)) {
                        return {
                            ...eq,
                            rooms: eq.rooms ? [...eq.rooms, newRoom] : [newRoom]
                        };
                    }
                    return eq;
                });
                setEquipment(updatedEquipment);
                break;
            case 'booking':
                const newbookingDTO = mapBookingToBookingDTO(entity)
                const bookingDTO = await BookingController.addBooking(newbookingDTO);
                const newBooking = transformBookingDTOToBooking(bookingDTO, rooms, clients, invoices, equipment);

                // Update special equipment associated with this new booking
                const updatedBookingEquipment = equipment.map(eq => {
                    if (newBooking.specialEquipment.some(se => se.id === eq.id)) {
                        return {
                            ...eq,
                            bookings: eq.bookings ? [...eq.bookings, newBooking] : [newBooking]
                        };
                    }
                    return eq;
                });
                setEquipment(updatedBookingEquipment);

                const matchingInvoice = invoices.find(invoice =>
                    invoice.bookings?.some(b => b.client?.id === newBooking.client?.id) &&
                    invoice.bookings?.some(b => dayjs(b.endDate).isSame(newBooking.endDate, 'day')) &&
                    invoice.bookings?.some(b => dayjs(b.startDate).isSame(newBooking.startDate, 'day')) &&
                    invoice.bookings?.some(b => b.room?.id !== newBooking.room?.id)
                );

                if (matchingInvoice) {
                    // Update existing invoice with this new booking
                    matchingInvoice.bookings = [...matchingInvoice.bookings!, newBooking];
                    await InvoiceController.updateInvoice(transformInvoiceToInvoiceDTO(matchingInvoice)); // Make sure you have a function to convert Invoice to InvoiceDTO
                    setInvoices(prev => prev.map(inv => inv.invoiceId === matchingInvoice.invoiceId ? matchingInvoice : inv));
                } else {
                    // Create a new invoice
                    const newInvoiceDTO = await InvoiceController.addInvoice({
                        userId: user ? user.id : '',
                        date: newBooking.startDate,
                        bookingIds: [newBooking.id],
                        customerDTO: clientToCustomerDTO(newBooking.client ? newBooking.client : emptyClient)

                    });
                    const newInvoice = transformInvoiceDTOToInvoice(newInvoiceDTO, [newBooking]);
                    setInvoices(prev => [...prev, newInvoice]);
                }

                setBookings(prev => [...prev, newBooking]);
                break;
            case 'invoice':
                const newInvoiceDTO = await InvoiceController.addInvoice(entity);
                setInvoices(prev => [...prev, transformInvoiceDTOToInvoice(newInvoiceDTO, bookings)]);
                break;
            default:
                throw new Error(`Unsupported entity type: ${type}`);
        }
    };
    
    const updateEntity = async (type: string, entity: any) => {
        switch (type) {
          case 'client':
            const updatedClient = await ClientController.updateClient(entity);
            setClients(prev => prev.map(c => c.id === updatedClient.id ? updatedClient : c));
            break;
          case 'room':
            const updatedRoomDTO = roomToPropertyDTO(entity);
            const updatedRoom = await RoomController.updateRoom(updatedRoomDTO);
            setRooms(prev => prev.map(r => r.id === updatedRoom.id ? updatedRoom : r));
            break;
          case 'booking':
            const updatedBookingDTO = mapBookingToBookingDTO(entity);
            const newBookingDTO = await BookingController.updateBooking(updatedBookingDTO);
            const newBooking = transformBookingDTOToBooking(newBookingDTO, rooms, clients, invoices, equipment);
            setBookings(prev => prev.map(b => b.id === newBooking.id ? newBooking : b));
            break;
          case 'invoice':
            const updatedInvoiceDTO = transformInvoiceToInvoiceDTO(entity);
            const updatedInvoice = await InvoiceController.updateInvoice(updatedInvoiceDTO);
            setInvoices(prev => prev.map(i => i.invoiceId === updatedInvoice.invoiceId ? updatedInvoice : i));
            break;
        }
      };
    
      const deleteEntity = async (type: string, id: string) => {
        switch (type) {
          case 'client':
            await ClientController.deleteClient(id);
            setClients(prev => prev.filter(c => c.id !== id));
            break;
          case 'room':
            await RoomController.deleteRoom(id);
            setRooms(prev => prev.filter(r => r.id !== id));
            break;
          case 'booking':
            await BookingController.deleteBooking(id);
            setBookings(prev => prev.filter(b => b.id !== id));
            break;
          case 'invoice':
            await InvoiceController.deleteInvoice(id);
            setInvoices(prev => prev.filter(i => i.invoiceId.toString() !== id));
            break;
        }
      };

    const contextValue = {
        clients,
        rooms,
        bookings,
        equipment,
        invoices,
        addEntity,
        updateEntity,
        deleteEntity,
    };

    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(DataContext);
    if (!context) throw new Error('useData must be used within a DataProvider');
    return context;
};
