import axios from 'axios';
import 'dayjs/locale/de'; // Assuming you need German locale support
import { Invoice } from '../model/RoomBooking'; // Adjust import paths as needed
import { InvoiceDTO } from '../model/dto/InvoiceDTO';
import { getAccessToken } from '../utils/AuthUtils';
import { createDisplayData } from '../components/data-display/DataDisplay';


const BASE_URL = 'http://localhost:3001';
const BASE_URL2 = process.env.REACT_APP_API_BASE_URL;

const InvoiceController = {

  fetchAllInvoices: async (): Promise<InvoiceDTO[]> => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get<InvoiceDTO[]>(`${BASE_URL2}/invoices`
        , {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response.data)
      return response.data;
    } catch (error) {
      console.error('Error fetching Invoices:', error);
      throw error;
    }
  },

  addInvoice: async (newInvoice: InvoiceDTO | undefined): Promise<InvoiceDTO> => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.post<InvoiceDTO>(`${BASE_URL2}/invoice`, newInvoice, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding Invoice:', error);
      throw error;
    }
  },

  updateInvoice: async (updatedInvoice: InvoiceDTO): Promise<Invoice> => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.patch<Invoice>(`${BASE_URL2}/invoice`, updatedInvoice, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating Invoice:', error);
      throw error;
    }
  },

  downloadPDFInvoice: async (invoiceId: string) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${BASE_URL2}/export/invoice/${invoiceId}/print`, {
        responseType: 'arraybuffer', // Specify response type as array buffer to handle binary data
        // method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      // Create a Blob object from the response data
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'invoice.pdf';

      // Trigger the download
      link.click();
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  },

sendDisplayData : async (invoice: Invoice) => {
    const displayData = createDisplayData(invoice);
  
    try {
      const accessToken = getAccessToken();

      const response = await axios.post(`${BASE_URL2}/export/processData`, displayData, {
        responseType: 'blob',  // Important for handling binary data
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
  
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Optional: trigger a file download directly from the response
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); // or dynamically determine the filename
      document.body.appendChild(link);
      link.click();
  
      return response.data;
    } catch (error) {
      console.error('Failed to process data:', error);
      throw error;
    }
  },

  // Delete Invoice
  deleteInvoice: async (invoiceId: string): Promise<void> => {
    try {
      const accessToken = getAccessToken();

      await axios.delete(`${BASE_URL}/invoice/${invoiceId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      console.log('Invoice deleted successfully');
    } catch (error) {
      console.error('Error deleting Invoice:', error);
      throw error;
    }
  }

};

export default InvoiceController;
