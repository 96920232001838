import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Booking } from '../../../model/RoomBooking';
import '../../../styles/App.css';

interface BookingCalendarProps {
    bookings: Booking[];
}

const BookingCalendar: React.FC<BookingCalendarProps> = ({ bookings }) => {

    // Log all booked days when bookings prop changes
    useEffect(() => {
        bookings.forEach(booking => {
            console.log(`Booking from ${booking.startDate} to ${booking.endDate}`);
        });

        // Optionally, if you want to log all distinct days that are booked
        const allBookedDays = new Set();
        bookings.forEach(booking => {
            const start = dayjs(booking.startDate);
            const end = dayjs(booking.endDate);
            let current = start;

            while (current.isBefore(end) || current.isSame(end, 'day')) {
                allBookedDays.add(current.format('YYYY-MM-DD'));
                current = current.add(1, 'day');
            }
        });

        console.log('All booked days:', Array.from(allBookedDays));
    }, [bookings]);

    const isBooked = (date: Date): boolean => {
        return bookings.some(booking => {
            const startDate = dayjs(booking.startDate);
            const endDate = dayjs(booking.endDate);
            const isBooked = dayjs(date).isBetween(startDate, endDate, null, '[]') ||
                dayjs(date).isSame(startDate, 'day') ||
                dayjs(date).isSame(endDate, 'day');

            if (isBooked) {
                console.log(`Booked: ${startDate.format('YYYY-MM-DD')} to ${endDate.format('YYYY-MM-DD')}`);
            }

            return isBooked;
        });
    };

    // Explicitly type the parameters for the tileClassName function
    const tileClassName = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month' && isBooked(date)) {
            return 'bookedDate'; // Ensure your CSS defines this class to highlight booked dates
        }
    };

    return (
        <div className='calendar-container'>
            <Calendar tileClassName={tileClassName}
                className="react-calendar" // Ensure your CSS styles this class for full width
            />
        </div>
    );

};

export default BookingCalendar