import dayjs from 'dayjs';


export function calculateNumberOfDays(startDate: string, endDate: string): number {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    // Calculate the difference in days
    const numberOfDays: number = end.diff(start, 'day');

    return numberOfDays;
}