import dayjs, { Dayjs } from 'dayjs';
import { Client } from '../RoomBooking';

// Assuming CustomerDTO mirrors the structure of your Client interface with necessary adjustments
export interface CustomerDTO {
  id?: string; // Assuming id is optional for creation
  kdNr?: number;
  anrede: string;
  name: string;
  strasse: string;
  wohnort: string;
  land: string;
  geburtstag: Dayjs | null;
  naechsteWerbung: string;
  standortStatt: string;
  objektAnschrift: string;
  benutzername: string;
  telefon: string;
  handy: string;
  // bookingIds: string[];
}

export interface EditCustomerFormProps {
  customer: CustomerDTO | null;
  open: boolean;
  onClose: () => void;
  onSave: (customer: CustomerDTO) => void;
}

export const emptyCustomer: CustomerDTO = {
  anrede: '',
  name: '',
  strasse: '',
  wohnort: '',
  land: '',
  geburtstag: null,
  naechsteWerbung: '',
  standortStatt: '',
  objektAnschrift: '',
  benutzername: '',
  telefon: '',
  handy: '',
};

// Mapper function
export const clientToCustomerDTO = (client: Client): CustomerDTO => {
    return {
      // Assuming the backend generates the UUID for new customers, so we don't map the id
      id: client.id ? client.id : undefined,
      kdNr: client.kdNr ? client.kdNr : undefined,
      anrede: client.anrede,
      name: client.name,
      strasse: client.strasse,
      wohnort: client.wohnort,
      land: client.land,
      geburtstag: client.geburtstag ? dayjs(client.geburtstag) : null,
      naechsteWerbung: client.naechsteWerbung,
      standortStatt: client.standortStadt,
      objektAnschrift: client.objektAnschrift,
      benutzername: client.benutzername,
      telefon: client.telefon,
      handy: client.handy,
    };
  };