import axios from 'axios';
import { BookingDTO } from '../model/dto/BookingDTO';
import { getAccessToken } from '../utils/AuthUtils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const BookingController = {

  fetchAllBookings: async (): Promise<BookingDTO[]> => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get<BookingDTO[]>(`${BASE_URL}/bookings`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching bookings:', error);
      throw error;
    }
  },


  addBooking: async (newBooking: BookingDTO): Promise<BookingDTO> => {
    console.log('addBooking')
    try {
      const accessToken = getAccessToken();

      const response = await axios.post<BookingDTO>(`${BASE_URL}/booking`, newBooking, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding Booking:', error);
      throw error;
    }
  },

  updateBooking: async (updatedBooking: BookingDTO): Promise<BookingDTO> => {
    console.log('updateBooking');
    try {
      const accessToken = getAccessToken();

      const response = await axios.patch<BookingDTO>(`${BASE_URL}/booking/${updatedBooking.id}`, updatedBooking, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      return response.data;
    } catch (error) {
      console.error('Error updating Booking:', error);
      throw error;
    }
  },

  // Delete Booking
  deleteBooking: async (bookingId: string): Promise<void> => {
    try {
      const accessToken = getAccessToken();

      await axios.delete(`${BASE_URL}/booking/${bookingId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      console.log('Booking deleted successfully');
    } catch (error) {
      console.error('Error deleting Booking:', error);
      throw error;
    }
  }


}


export default BookingController;
