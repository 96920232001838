import axios from 'axios';
import { Room, SpecialEquipment } from '../model/RoomBooking';
import { PropertyDTO } from '../model/dto/PropertyDTO';
import { getAccessToken } from '../utils/AuthUtils';
import { SpecialEquipmentDTO } from '../model/dto/SpecialEquipmentDTO';
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RoomController = {

  fetchAllRooms: async (): Promise<PropertyDTO[]> => {
    try {
      const accessToken = getAccessToken();

      const response = await axios.get<PropertyDTO[]>(`${BASE_URL}/properties`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching rooms:', error);
      throw error;
    }
  },


  addProperty: async (newRoom: PropertyDTO): Promise<PropertyDTO> => {
    console.log('addProperty')
    try {
      const accessToken = getAccessToken();

      const response = await axios.post<PropertyDTO>(`${BASE_URL}/property`, newRoom, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding property:', error);
      throw error;
    }
  },

  updateRoom: async (room: PropertyDTO): Promise<Room> => {
    console.log('updateRoom')
    try {
      console.log(room)
      const accessToken = getAccessToken();
      const response = await axios.patch<Room>(`${BASE_URL}/property`, room, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding Room:', error);
      throw error;
    }
  },

  // Delete Room
  deleteRoom: async (roomId: string): Promise<void> => {
    try {
      const accessToken = getAccessToken();

      await axios.delete(`${BASE_URL}/room/${roomId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      console.log('Room deleted successfully');
    } catch (error) {
      console.error('Error deleting Room:', error);
      throw error;
    }
  },

  fetchAllEquipment: async (): Promise<SpecialEquipmentDTO[]> => {
    try {
      const accessToken = getAccessToken();

      const response = await axios.get<SpecialEquipmentDTO[]>(`${BASE_URL}/equipments`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching rooms:', error);
      throw error;
    }
  },

  addEquipment: async (newEquipment: SpecialEquipment[]): Promise<SpecialEquipment[]> => {
    console.log('addEquipments')
    try {
      const accessToken = getAccessToken();
      const response = await axios.post<SpecialEquipment[]>(`${BASE_URL}/equipments`, newEquipment, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error adding property:', error);
      throw error;
    }
  },

};

export default RoomController;
