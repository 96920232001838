// src/contexts/AuthContext.tsx
import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { User } from '../model/User';

interface AuthContextType {
    isAuthenticated: boolean;
    isAuthChecking: boolean;
    user: User | null;
    login: (token: string, user: User) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAuthChecking, setIsAuthChecking] = useState<boolean>(true); // Initially true, as we're checking the status
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('authTokens');
        const storedUser = localStorage.getItem('user');
        const isAuthenticatedState = !!token;
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
        console.log('Auth State on Load:', isAuthenticatedState); // Debugging line
        setIsAuthenticated(isAuthenticatedState);
        setIsAuthChecking(false); // Auth check completed
    }, []);

    const login = (token: string, user: User) => {
        localStorage.setItem('authTokens', token);
        setIsAuthenticated(true);
        setUser(user); 
        setIsAuthChecking(false);
        localStorage.setItem('user', JSON.stringify(user));
        window.dispatchEvent(new Event('authChange'));
    };
    
    const logout = () => {
        localStorage.removeItem('authTokens');
        setIsAuthenticated(false);
        setIsAuthChecking(false);
        setUser(null); 
        window.dispatchEvent(new Event('authChange'));
    };
    

    return (
        <AuthContext.Provider value={{ isAuthenticated, isAuthChecking, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
