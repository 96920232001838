import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import InvoiceDetailsPanel from '../components/data-display/InvoiceDetailsPanel';
import InvoiceForm from '../components/forms/InvoiceForm';
import { useData } from '../contexts/DataContext';
import InvoiceController from '../controllers/InvoiceController';
import { Invoice } from '../model/RoomBooking';
import de from '../resources/locales/local-de';
import '../styles/App.css';
import { calculateMwst19, calculateMwst7 } from '../utils/PriceUtil';



const Umsatzliste: React.FC = () => {
    const { invoices, addEntity, updateEntity } = useData();
    const [rows, setRows] = useState<Invoice[]>([]);
    const [selectedRow, setSelectedRow] = useState<Invoice | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const columns: GridColDef[] = [
        {
            field: 'bookings', headerName: de.common.bookings, width: 130, renderCell: (params) => {
                if (Array.isArray(params.value)) {
                    return <span>{params.value.map((booking: any) => booking.bun).join(', ')}</span>;
                }
                return <span>{de.common.noBookingsFound}</span>;
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            renderCell: (params) => {
                const currentDate = dayjs();
                const dueDate = dayjs(params.row.dueDate);
                if (currentDate.isBefore(dueDate, 'day')) {
                    return 'Future';
                } else if (currentDate.isSame(dueDate, 'day')) {
                    return 'Ongoing';
                } else {
                    return 'Past';
                }
            },
        },
        {
            field: 'sendInvoice',
            headerName: 'Send Invoice',
            width: 130,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    onClick={() => handleSendInvoice(params.row)}
                >
                    Send
                </Button>
            ),
        },
        {
            field: 'invoiceId', headerName: "id", width: 130, valueGetter: (params) => {
                return params.row.invoiceId
            }
        },
        {
            field: 'Anrede', headerName: "Anrede", width: 150, valueGetter: (params) => {
                console.log(params)
                if (!params.row.bookings[0].client) {
                    return
                }
                return params.row.bookings[0].client.anrede
            }
        },
        {
            field: 'Name', headerName: de.clientDetails.name, width: 150, valueGetter: (params) => {
                console.log(params)
                if (!params.row.bookings[0].client) {
                    return
                }
                return params.row.bookings[0].client.name
            }
        },
        {
            field: 'strasse', headerName: de.clientDetails.street, width: 150, valueGetter: (params) => {
                console.log(params)
                if (!params.row.bookings[0].client) {
                    return
                }
                return params.row.bookings[0].client.strasse
            }
        },
        {
            field: 'wohnort', headerName: de.clientDetails.residence, width: 130, valueGetter: (params) => {
                if (!params.row.bookings[0].client) {
                    return
                }
                return params.row.bookings[0].client.wohnort
            }
        },
        {
            field: 'reNr', headerName: de.invoiceDetails.invoiceNumber, width: 100, valueGetter: (params) => {
                return params.row.invoiceId
            }
        },

        {
            field: 'jahr', headerName: de.invoiceDetails.year, width: 100, valueGetter: (params) => {
                return dayjs(params.row.createdDate).year()
            }
        },
        {
            field: 'monat', headerName: de.invoiceDetails.month, width: 100, valueGetter: (params) => {
                return dayjs(params.row.createdDate).month()
            }
        },
        {
            field: 'tag', headerName: de.invoiceDetails.day, width: 100, valueGetter: (params) => {
                return dayjs(params.row.createdDate).day()
            }
        },

        {
            field: 'netto', headerName: de.invoiceDetails.net, width: 120, valueGetter: (params) => {
                return params.row.invoiceId
            }
        },
        {
            field: 'mwst7', headerName: de.invoiceDetails.vat7, width: 130, valueGetter: (params) => {
                return calculateMwst7(params.row)
            }
        },
        {
            field: 'mwst19', headerName: de.invoiceDetails.vat19, width: 160, valueGetter: (params) => {
                return calculateMwst19(params.row.invoiceId)
            }
        },
        {
            field: 'grundDerBeherberung', headerName: de.invoiceDetails.reasonForAccommodation, width: 160, valueGetter: (params) => {
                return params.row.bookings[0].grundDerBeherbergung
            }
        },
        {
            field: 'cityTax', headerName: de.invoiceDetails.cityTax, width: 180, valueGetter: (params) => {
                return params.row.bookings[0].cityTax
            }
        },
        {
            field: 'kurTax', headerName: de.invoiceDetails.visitorsTax, width: 150, valueGetter: (params) => {
                return params.row.bookings[0].kurTax
            }
        },
        {
            field: 'umsatzMitTax', headerName: de.invoiceDetails.revenueWithTax, width: 130, valueGetter: (params) => {
                return params.row.invoiceId
            }
        },
        {
            field: 'belegNr', headerName: de.invoiceDetails.invoiceNumber, width: 130, valueGetter: (params) => {
                return params.row.invoiceId
            }
        },
        {
            field: 'kaufdatum', headerName: de.invoiceDetails.purchaseDate, width: 130, valueGetter: (params) => {
                return params.row.invoiceId
            }
        },
        {
            field: 'netto2', headerName: de.invoiceDetails.net, width: 130, valueGetter: (params) => {
                return params.row.invoiceId
            }
        },
        {
            field: 'mwst', headerName: de.invoiceDetails.vat, width: 130, valueGetter: (params) => {
                return params.row.invoiceId
            }
        },
        {
            field: 'kosten', headerName: de.invoiceDetails.costs, width: 130, valueGetter: (params) => {
                return params.row.invoiceId
            }
        },
    ];

    useEffect(() => {
        setRows(invoices);
    }, [invoices]);

    const handleRowClick = (params: any) => {
        setSelectedRow(params.row)
    };

    const handleEditOpen = () => setIsEditModalOpen(true);
    const handleEditClose = () => setIsEditModalOpen(false);

    const handleSaveEditedRow = (editedRow: Invoice) => {
        if (editedRow.invoiceId === -1) {
            setRows([...rows, { ...editedRow }]);
            addEntity('invoice', editedRow);
        } else {
            updateEntity('invoice', editedRow);
            setRows(rows.map(row => (row.invoiceId === editedRow.invoiceId ? editedRow : row)));
        }
        handleEditClose();
    };

    const handleSendInvoice = (invoice: Invoice) => {
        console.log(`Sending invoice with ID: ${invoice.invoiceId}`);
        InvoiceController.sendDisplayData(invoice)
    };

    return (
        <div>
            {/* <Button variant="contained" onClick={() => { setSelectedRow(null); handleEditOpen(); }}>
                {de.common.addNew} 
            </Button> */}
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    getRowId={(row) => row.invoiceId}
                    rows={rows}
                    columns={columns}
                    onRowClick={handleRowClick}
                    onCellDoubleClick={() => { }}
                />
            </div>
            {selectedRow && (
                <div>
                    <Button variant="contained" onClick={() => { setSelectedRow(selectedRow); handleEditOpen(); }}>
                        {de.common.edit}
                    </Button>
                    <InvoiceDetailsPanel invoice={selectedRow}></InvoiceDetailsPanel>
                </div>
            )}
            <InvoiceForm
                invoice={selectedRow}
                open={isEditModalOpen}
                onClose={handleEditClose}
                onSave={handleSaveEditedRow}
            />
        </div>
    );
};

export default Umsatzliste;

