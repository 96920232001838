import { findAssociatedBookings, findAssociatedRooms } from "../../contexts/ContextUtils";
import { Booking, Room, SpecialEquipment } from "../RoomBooking";

export interface SpecialEquipmentDTO {
    id: string;
    name: string;
    bookingIds: string[];
    propertyIds: string[];
    price: number;
}

export const specialEquipmentDTOToEquipment = (dto: SpecialEquipmentDTO, bookings: Booking[], rooms: Room[]) : SpecialEquipment => {
    const  specialEquipment : SpecialEquipment = {
        id: dto.id,
        name: dto.name,
        bookings: findAssociatedBookings(dto.bookingIds, bookings),
        rooms: findAssociatedRooms(dto.propertyIds, rooms),
        price: dto.price
    }
    return specialEquipment;
}


// Transformation utility to enrich DTOs with model data
export const transformEquipmentDTOToEquipment = (dto: SpecialEquipmentDTO, bookings: Booking[], rooms: Room[]): SpecialEquipment => ({
        id: dto.id,
        name: dto.name,
        bookings: bookings.filter(b => dto.bookingIds.includes(b.id)),
        rooms: rooms.filter(r => dto.propertyIds.includes(r.id)),
        price: dto.price
  });