import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const EmailActivation: React.FC = () => {
  const { tokenId } = useParams<{ tokenId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const activateEmail = async () => {
      try {
        const response = await axios.post(`https://api.zimmer.ferendin.com/auth/confirm/action/${tokenId}`);
        console.log('Email activation successful', response.data);
        navigate('/'); // Redirect to home or login page
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          // Now we can safely access error.response
          console.error('Failed to activate email:', error.response?.data || error.message);
        } else {
          console.error('An unexpected error occurred:', error);
        }
        // Here you can add navigation or error messages depending on your UI needs
      }
    };

    if (tokenId) {
      activateEmail();
    }
  }, [tokenId, navigate]);

  return (
    <div>
      Activating your email, please wait...
    </div>
  );
};

export default EmailActivation;
