import { Box, Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useData } from '../../contexts/DataContext';
import { Client } from '../../model/RoomBooking';

const BirthdayTable: React.FC = () => {
    const { clients } = useData(); // Fetch clients from your data context
    const [next7DaysClients, setNext7DaysClients] = useState<Client[]>([]);
    const [next14DaysClients, setNext14DaysClients] = useState<Client[]>([]);

    useEffect(() => {
        const now = dayjs();
        const next7Days = now.add(7, 'day');
        const next14Days = now.add(14, 'day');

        const clientsWithin7Days = clients.filter(client => {
            const birthdayString = client.geburtstag;
            const birthday = dayjs(birthdayString)
            return (
                (birthday.month() === now.month() && birthday.date() >= now.date()) || // Birthday in current month after or on today
                (birthday.month() === next7Days.month() && birthday.date() < next7Days.date()) // Birthday in next month before next 7 days
            );
        });

        const clientsWithin14Days = clients.filter(client => {
            const birthday = dayjs(client.geburtstag);
            return (
                (birthday.month() === next7Days.month() && birthday.date() >= next7Days.date()) || // Birthday in next month after or on next 7 days
                (birthday.month() === next14Days.month() && birthday.date() < next14Days.date()) // Birthday in next month before next 14 days
            );
        });

        setNext7DaysClients(clientsWithin7Days);
        setNext14DaysClients(clientsWithin14Days);
    }, [clients]);


    const handleSendEmail = (client: Client) => {
        const { name, email } = client;
      
        // Example implementation using window.open to open a mail client
        const subject = 'Birthday Greetings';
        const body = `Dear ${name},\n\nHappy Birthday!\n\nBest wishes,\nYour Name`;
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      
        window.open(mailtoLink);
      };


    return (
        <Container maxWidth="lg">
            <Box sx={{ mb: 3 }}>
                <Typography variant="h4" gutterBottom>Upcoming Birthdays</Typography>
                <Paper elevation={3}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Birthday</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {next7DaysClients.map(client => (
                                    <TableRow key={client.id} style={{ backgroundColor: 'red' }}>
                                        <TableCell>{client.name}</TableCell>
                                        <TableCell>{dayjs(client.geburtstag).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={() => handleSendEmail(client)}>Send Email</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {next14DaysClients.map(client => (
                                    <TableRow key={client.id} style={{ backgroundColor: 'orange' }}>
                                        <TableCell>{client.name}</TableCell>
                                        <TableCell>{dayjs(client.geburtstag).format('YYYY-MM-DD')}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={() => handleSendEmail(client)}>Send Email</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </Container>
    );
};

export default BirthdayTable;