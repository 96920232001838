import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Booking } from '../../model/RoomBooking';
import '../../styles/App.css';

interface BookingsTableProps {
  bookings: Booking[];
  sortField: string;
}

const BookingsTable: React.FC<BookingsTableProps> = ({ bookings, sortField  }) => {
  const [rows, setRows] = useState<Booking[]>(bookings);


  // Define columns for DataGrid
  const columns: GridColDef[] = [
    { field: 'bun', headerName: 'BUN', width: 130 },
    { field: 'kdNr', headerName: 'Client ID', width: 130 },
    { field: 'roomId', headerName: 'Room ID', width: 130 },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 130,
      valueGetter: (params) => dayjs(params.row.startDate).format('YYYY-MM-DD'),
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 130,
      valueGetter: (params) => dayjs(params.row.endDate).format('YYYY-MM-DD'),
    },
    // Add more columns as needed
  ];

  useEffect(() => {
    console.log(bookings)
    setRows(bookings);
  }, [bookings]);

  return (
    <Box className="dataGridContainer">
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        initialState={{
          sorting: {
            sortModel: [{ field: sortField, sort: 'asc' }],
          },
        }}
      />
    </Box>
  );
};

export default BookingsTable;
