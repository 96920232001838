// DetailsPanel.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { Chip } from '@mui/material';
import { Booking, Room } from '../../model/RoomBooking'; // Import the new entities
import de from '../../resources/locales/local-de';

interface DetailsPanelProps {
  roomDetails: Room | null;
  bookingDetails: Booking[] | null;
}

const DetailsPanel: React.FC<DetailsPanelProps> = ({ roomDetails, bookingDetails }) => {

  const navigate = useNavigate(); // Initialize useNavigate hook

  // Function to handle click on "Room Details"
  const handleRoomDetailsClick = () => {
    if (roomDetails) {
      navigate(`/room/${roomDetails.id}`); // Navigate to the RoomDetailsPage with the room's ID
    }
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {roomDetails && (
          <div style={{ marginRight: '20px' }}>
            <h3 style={{ cursor: 'pointer' }} onClick={handleRoomDetailsClick}>{de.detailsPanel.roomDetails}</h3>
            <p>{de.detailsPanel.location}: {roomDetails.location}</p>
            <p>{de.detailsPanel.address}: {roomDetails.address}</p>
            <p>{de.detailsPanel.roomType}: {roomDetails.roomType}</p>
            <p>{de.detailsPanel.numberOfPeople}: {roomDetails.numberOfPeople}</p>
            <p>{de.detailsPanel.nextCleaning}: {roomDetails.nextCleaning}</p>
            <p>{de.detailsPanel.nextMattressChange}: {roomDetails.nextMattressChange}</p>
            <p>{de.detailsPanel.nextGasDelivery}: {roomDetails.nextGasDelivery}</p>
            <p>{de.detailsPanel.price}: {roomDetails.price}</p>
            {/* Render chips for special equipment */}
            <div>
              <p>{de.detailsPanel.specialEquipment}:</p>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {roomDetails.specialEquipment.map((equipment) => (
                  <Chip key={equipment.id} label={equipment.name} style={{ margin: '2px' }} />
                ))}
              </div>
            </div>
          </div>
        )}
        <div>
          <h3>{de.detailsPanel.bookingDetails}</h3>
          {bookingDetails && bookingDetails.length > 0 ? (
            <ul>
              {bookingDetails.map((booking, index) => (
                <li key={index}>
                  <p>{de.common.bookingNumber}: {booking.bun}</p>
                  <p>{de.common.startDate}: {booking.startDate}</p>
                  <p>{de.common.endDate}: {booking.endDate}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>{de.common.noBookingsFound}</p>
          )}
        </div>
      </div>
    </div>


  );
};

export default DetailsPanel;
