import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Client } from '../../model/RoomBooking';
import de from '../../resources/locales/local-de';

interface ClientDetailsProps {
  row: Client | null;
}

const RowDetails: React.FC<ClientDetailsProps> = ({ row }) => {
  const navigate = useNavigate();

  const handleClientDetailsClick = () => {
    if (row) {
      navigate(`/client/${row.id}`);
    }
  };

  if (!row) {
    return <div>{de.common.noClientsFound}</div>;
  }

  const bookings = row.bookings || [];

  return (
    <div>
      <h3 style={{ cursor: 'pointer' }} onClick={handleClientDetailsClick}>{de.clientDetails.title}</h3>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
        <div>
          <p>{de.clientDetails.customerNumber}: {row.kdNr}</p>
          <p>{de.clientDetails.salutation}: {row.anrede}</p>
          <p>{de.clientDetails.name}: {row.name}</p>
          <p>{de.clientDetails.street}: {row.strasse}</p>
          <p>{de.clientDetails.residence}: {row.wohnort}</p>
          <p>{de.clientDetails.country}: {row.land}</p>
          <p>{de.clientDetails.birthday}: {row.geburtstag}</p>
          <p>{de.clientDetails.nextAdvertisement}: {row.naechsteWerbung}</p>
          <p>{de.clientDetails.locationCity}: {row.standortStadt}</p>
          <p>{de.clientDetails.objectAddress}: {row.objektAnschrift}</p>
          <p>{de.clientDetails.username}: {row.benutzername}</p>
          <p>{de.clientDetails.phone}: {row.telefon}</p>
          <p>{de.clientDetails.mobile}: {row.handy}</p>
        </div>
        <div>
          <ul>
            <p>{de.common.bookings}:</p>
            {bookings.map((booking, index) => (
              <li key={index}>
                <p>BUN: {booking.bun}</p>
                <p>{de.common.startDate}: {booking.startDate}</p>
                <p>{de.common.endDate}: {booking.endDate}</p>
                <p>{de.detailsPanel.reasonForAccommodation}: {booking.grundDerBeherbergung}</p>
                <p>{de.detailsPanel.calculationMethodCityTax}: {booking.berechnungsartDerCityTax}</p>
                <p>{de.detailsPanel.calculationCityTax}: {booking.kurTaxeBerechnen ? de.common.yes : de.common.no}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RowDetails;
