import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Footer from './components/common/Footer';
import Navbar from './components/common/Navbar';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { DataProvider } from './contexts/DataContext';
// import Home from './Home'; // Your home component
import Uebersichtstafel from './Pages/Uebersichtstafel'; // Your Übersichtstafel component
// import Umsatzliste from './Umsatzliste'; // Your Umsatzliste component
import Belegungsplan from './Pages/Belegungsplan'; // Your Belegungsplan component
import BuchungenPage from './Pages/BuchungenPage';
import ClientDetailsPage from './Pages/ClientDetailsPage';
import LoginPage from './Pages/LoginPage';
import RegisterPage from './Pages/RegisterPage';
import RoomDetailsPage from './Pages/RoomDetailsPage'; // Assume you create this component

import { Container } from '@mui/material';
import HomePage from './Pages/HomePage';
import './styles/App.css';
import Umsatzliste from './Pages/Umsatzliste';
import EmailActivation from './Pages/EmailActivationPage';


// Protected Route Component
const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated, isAuthChecking } = useAuth();
  console.log('ProtectedRoute, isAuthenticated:', isAuthenticated); // Should log true if user is authenticated
  if (isAuthChecking) {
    return <div>Loading...</div>; // Or any other loading indicator
  }
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <Router>
          <div className="app-container">
            <Navbar />
            <Container component="main" maxWidth="lg" className="main-content">
              <Routes>
                  <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                  <Route path="/authentication/activate-email/:tokenId" element={<EmailActivation />} />
                  <Route path="/uebersichtstafel" element={<ProtectedRoute><Uebersichtstafel /></ProtectedRoute>} />
                  <Route path="/umsatzliste" element={<ProtectedRoute><Umsatzliste /></ProtectedRoute>} />
                  {/* <Route path="/umsatzliste" element={<Umsatzliste />} /> */}
                  <Route path="/belegungsplan" element={<ProtectedRoute><Belegungsplan /></ProtectedRoute>} />
                  <Route path="/buchungen" element={<ProtectedRoute><BuchungenPage /></ProtectedRoute>} />
                  <Route path="/room/:roomId" element={<ProtectedRoute><RoomDetailsPage /></ProtectedRoute>} />
                  <Route path="/client/:clientId" element={<ProtectedRoute><ClientDetailsPage /></ProtectedRoute>} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />

              </Routes>
            </Container>
            <Footer />
          </div>
        </Router>
      </DataProvider>
    </AuthProvider>


  );
}

export default App;
