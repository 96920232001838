export const getAccessToken = () => {
    const token = localStorage.getItem('authTokens');
    if (token !== null) {
      // Parse the JSON string to an object
      const authToken = JSON.parse(token);
      // Access the accessToken property
      return authToken.accessToken;
    } else {
        throw new Error('No auth token found');
    }
}