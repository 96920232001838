import { GridColDef } from '@mui/x-data-grid';

// Function to generate columns specific for rooms
export const roomColumns: GridColDef[] = [
  { field: 'location', headerName: 'Standort / Stadt', width: 130 },
  { field: 'address', headerName: 'Objekt / Anschrift', width: 150 },
  { field: 'roomType', headerName: 'Bezeichnung Zimmer', width: 150 },
  { field: 'numberOfPeople', headerName: 'Anzahl der Personen', width: 180 },
  { field: 'nextCleaning', headerName: 'Nächste Grundreinigung', width: 200 },
  { field: 'availableFrom', headerName: 'Zimmer frei ab dem', width: 180 },
  { field: 'nextMattressChange', headerName: 'Nächster Wechsel der Matratzen', width: 230 },
  { field: 'nextGasDelivery', headerName: 'Nächste Gas Lieferung', width: 200 },
  { field: 'price', headerName: 'Zimmerpreis', width: 130 },
  // {
  //   field: 'specialEquipment', headerName: 'Gesonderte Ausstattung', width: 220, renderCell: (params) => {
  //     if (Array.isArray(params.value)) {
  //       return <span>{params.value.map((eq: any) => eq.name).join(', ')}</span>;
  //     }
  //     return <span>{de.common.noBookingsFound}</span>;
  //   }
  // },
  { field: 'merkmal5', headerName: 'Merkmal 5', width: 130 },
];


