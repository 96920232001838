import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { Client } from '../model/RoomBooking';
import { CustomerDTO } from '../model/dto/CustomerDTO';
import { getAccessToken } from '../utils/AuthUtils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TableRowController = {
  fetchAllClients: async (): Promise<Client[]> => {
    try {
      const accessToken = getAccessToken();
      console.log(accessToken)
      const response = await axios.get<Client[]>(`${BASE_URL}/customers`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      console.log("response.data")
      console.log(response)
      return response.data;
    } catch (error) {
      console.error('Error fetching Clients:', error);
      throw error;
    }
  },

  addClient: async (newClient: CustomerDTO): Promise<Client> => {
    console.log('addClient')
    try {
        const accessToken = getAccessToken();
        const response = await axios.post<Client>(`${BASE_URL}/customer`, {
          ...newClient,
          geburtstag: newClient.geburtstag ? dayjs(newClient.geburtstag).format('YYYY-MM-DD') : null, // Adjusting date format
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        });
        return response.data;
    } catch (error) {
      console.error('Error adding Client:', error);
      throw error;
    }
  },

  updateClient: async (client: CustomerDTO): Promise<Client> => {
    console.log('updateClient')
    try {
        console.log(client)
        const accessToken = getAccessToken();
        const response = await axios.patch<Client>(`${BASE_URL}/customer`, {
          ...client,
          geburtstag: client.geburtstag ? dayjs(client.geburtstag).format('YYYY-MM-DD') : null, // Adjusting date format
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        });
        return response.data;
    } catch (error) {
      console.error('Error adding Client:', error);
      throw error;
    }
  },

    deleteClient : async (clientId: string): Promise<void> => {
      try {
        const accessToken = getAccessToken();
    
        await axios.delete(`${BASE_URL}/client/${clientId}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });
    
        console.log('Client deleted successfully');
      } catch (error) {
        console.error('Error deleting Client:', error);
        throw error;
      }
    }


};

export default TableRowController;
