import { Button, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DetailsPanel from '../components/data-display/RoomDetailsPanel';
import DateRangeGrid from '../components/data-display/reusable/DateRangeGrid';
import EditRoomForm from '../components/forms/RoomForm';
import { useData } from '../contexts/DataContext';
import { Room } from '../model/RoomBooking';
import de from '../resources/locales/local-de';

const BelegungsplanPage: React.FC = () => {
  const navigate = useNavigate();
  const { rooms, addEntity, updateEntity } = useData();
  const [rows, setRows] = useState<Room[]>([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs().add(1, 'month'));
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    setRows(rooms); 
  }, [rooms]); 

  const handleRowClick = (params: any) => {
    setSelectedRoom(params.row);
  };

  const handleRowDoubleClick = (params: any) => {
    navigate(`/room/${params.row.id}`);
  };

  const handleEditOpen = () => setIsEditModalOpen(true);
  const handleEditClose = () => {
    setIsEditModalOpen(false);
    setSelectedRoom(null); // Reset selectedRoom when closing the form
  };

  const handleSaveEditedRoom = (editedRow: Room) => {
    if (editedRow.id === '') {
      addEntity('room', editedRow);
      setRows([...rows, { ...editedRow }]);
    } else {
      updateEntity('room', editedRow);
      setRows(rows.map(row => (row.id === editedRow.id ? editedRow : row)));
    }
    handleEditClose();
  };

  return (
    <div className="pageContainer">
      <Grid container spacing={2} alignItems="center" justifyContent="space-between" className="datePickerContainer">
        <Grid item>
          <Button variant="contained" onClick={handleEditOpen}>{de.roomOccupancy.addNewRoom}</Button>
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          <Grid item>
            <DatePicker
              label={de.common.startDate}
              value={startDate}
              onChange={setStartDate}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label={de.common.endDate}
              value={endDate}
              onChange={setEndDate}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
      <DateRangeGrid
        startDate={startDate?.toDate() || new Date()}
        endDate={endDate?.toDate() || new Date()}
        data={rooms}
        dataType="room"
        handleRowClick={handleRowClick}
        handleRowDoubleClick={handleRowDoubleClick}
      />
      {selectedRoom && (<div>
        <Button variant="contained" onClick={() => { setSelectedRoom(selectedRoom); handleEditOpen(); }}>
          {de.common.edit}
        </Button>
        <DetailsPanel roomDetails={selectedRoom} bookingDetails={selectedRoom.bookings || []} />
      </div>
      )}
      <EditRoomForm
        room={selectedRoom}
        open={isEditModalOpen}
        onClose={handleEditClose}
        onSave={handleSaveEditedRoom}
      />
    </div>
  );
};

export default BelegungsplanPage;
