// hooks/useFetch.js
import { useCallback, useState } from 'react';
import BookingController from '../../controllers/BookingController';
import ClientController from '../../controllers/ClientController';
import InvoiceController from '../../controllers/InvoiceController';
import RoomController from '../../controllers/RoomController';
import { Booking, Client, Invoice, Room, SpecialEquipment } from '../../model/RoomBooking';
import { transformBookingDTOToBooking } from '../../model/dto/BookingDTO';
import { transformInvoiceDTOToInvoice } from '../../model/dto/InvoiceDTO';
import { transformRoomDTOToRoom } from '../../model/dto/PropertyDTO';
import { transformEquipmentDTOToEquipment } from '../../model/dto/SpecialEquipmentDTO';

interface FetchData {
    clients: Client[];
    rooms: Room[];
    bookings: Booking[];
    equipment: SpecialEquipment[];
    invoices: Invoice[];
}

export const useFetch = () => {
    const [data, setData] = useState<FetchData>({
        clients: [],
        rooms: [],
        bookings: [],
        equipment: [],
        invoices: []
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const fetchData = useCallback(async (user: any, isAuthenticated: boolean) => {
        if (!user || !isAuthenticated) {
            console.log('No user logged in or user not authenticated');
            return;
        }

        try {
            setLoading(true);

            // Simultaneously fetch all data as these can be independent at this stage
            const [clients, roomsDTO, bookingsDTO, invoicesDTO, equipmentDTO] = await Promise.all([
                ClientController.fetchAllClients(),
                RoomController.fetchAllRooms(),
                BookingController.fetchAllBookings(),
                InvoiceController.fetchAllInvoices(),
                RoomController.fetchAllEquipment()
            ]);

            const equipment = equipmentDTO.map(dto => transformEquipmentDTOToEquipment(dto, [], []))

            let rooms = roomsDTO.map(dto => transformRoomDTOToRoom(dto, equipment, [])); // Passing empty array for equipment temporarily


            let bookings = bookingsDTO.map(dto => transformBookingDTOToBooking(dto, rooms, clients, [], equipment));

            // Now link the bookings to the invoices
            const invoices = invoicesDTO.map(dto => transformInvoiceDTOToInvoice(dto, bookings));

            // Update bookings to include references to the corresponding invoices
            bookings = bookings.map(booking => {
                const associatedInvoice = invoices.find(invoice => invoice.bookings?.some(b => b.id === booking.id));
                return { ...booking, invoice: associatedInvoice }; // Attach the found invoice or keep it undefined
            });

            // Update invoices to make sure their bookings are correctly linked
            invoices.forEach(invoice => {
                // Ensure invoice.bookings is initialized or use a fallback to an empty array
                invoice.bookings = invoice.bookings
                    ? bookings.filter(booking => invoice.bookings?.some(b => b.id === booking.id))
                    : [];
            });

            // Update rooms with bookings
            rooms = rooms.map(room => {
                return { ...room, bookings: bookings.filter(booking => booking.room?.id === room.id) };
            });

            // Update equipment with rooms and bookings
            equipment.forEach(eq => {
                eq.rooms = rooms.filter(room => room.specialEquipment.some(se => se.id === eq.id));
                eq.bookings = bookings.filter(booking => booking.specialEquipment.some(se => se.id === eq.id));
            });

            setData({
                clients,
                rooms,
                bookings,
                equipment,
                invoices
            });
        } catch (e: any) {
            setError(e);
            console.error('Failed to fetch data:', e);
        } finally {
            setLoading(false);
        }
    }, []);

    return { data, loading, error, fetchData };
};