import { Box, Button, Container, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RegisterPage.css';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(request => {
    console.log('Making request to:', request.url);
    console.log('Full request URL:', `${request.baseURL}${request.url}`);
    return request;
  }, error => {
    return Promise.reject(error);
  });

const RegisterPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>(''); // Additional field example
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
        await api.post('/auth/register', {
          email,
          password,
          name: username,
        });
  
        // Optional: Navigate to the login page or directly log in the user
        navigate('/login');
      } catch (err) {
        setError('Registration failed. Please try again.');
        console.error('Error during registration:', err);
      }
  };

  return (
    <Container component="main" maxWidth="xs" className="register-container">
      <Box className="register-box">
        <Typography component="h1" variant="h5">Register</Typography>
        {error && <Typography color="error" className="error-message">{error}</Typography>}
        <Box component="form" onSubmit={handleRegister} noValidate sx={{ mt: 1 }} className="register-form">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-field"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-field"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-field"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            className="register-button"
          >
            Register
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default RegisterPage;
