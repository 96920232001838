import { Booking, Client, Invoice, Room, SpecialEquipment } from "../RoomBooking";

export interface BookingDTO {
  id?: string; // Optional for new bookings
  bun?: number;
  startDate: string;
  endDate: string;
  grundDerBeherbergung: string;
  berechnungsartDerCityTax: string;
  kurTaxeBerechnen: boolean;
  propertyId?: string; // UUID of the property
  customerId?: string; // UUID of the customer
  kdNr?:number;
  roomId?:number;
  specialEquipment: string[];
  invoiceId?:number;
}

export function getSpecialEquipmentIds(booking: Booking): string[] {
  return booking.specialEquipment.map((equipment: SpecialEquipment) => equipment.id);
}

export const mapBookingToBookingDTO = (booking: Booking): BookingDTO => {
  return {
    id: booking.id,
    bun: booking.bun,
    startDate: booking.startDate,
    endDate: booking.endDate,
    grundDerBeherbergung: booking.grundDerBeherbergung,
    berechnungsartDerCityTax: booking.berechnungsartDerCityTax,
    kurTaxeBerechnen: booking.kurTaxeBerechnen,
    kdNr: booking.client?.kdNr,
    roomId: booking.room?.roomId,
    propertyId: booking.room?.id,
    customerId: booking.client?.id,
    specialEquipment: getSpecialEquipmentIds(booking),
    invoiceId: booking.invoice?.invoiceId
  };
};


export const mapBookingDTOToBooking = (bookingDTO: BookingDTO): Booking => {
   return {
    id: bookingDTO.id || '', // Generate or assign a new ID as necessary
    bun: bookingDTO.bun || Math.floor(Math.random() * 10000) + 1, // Generate a random bun if not provided
    startDate: bookingDTO.startDate,
    endDate: bookingDTO.endDate,
    grundDerBeherbergung: bookingDTO.grundDerBeherbergung,
    berechnungsartDerCityTax: bookingDTO.berechnungsartDerCityTax,
    kurTaxeBerechnen: bookingDTO.kurTaxeBerechnen,
    specialEquipment: [],
  };
}

export const transformBookingDTOToBooking = (dto: BookingDTO, rooms: Room[], clients: Client[], invoices: Invoice[], specialEquipments : SpecialEquipment[]): Booking => ({
  id: dto.id ? dto.id : '',
  bun: dto.bun ? dto.bun : -1, // Set or calculate
  room: rooms.find(r => r.roomId === dto.roomId),
  client: clients.find(c => c.id === dto.customerId),
  invoice: invoices.find(i => i.invoiceId === dto.invoiceId),
  startDate: dto.startDate,
  endDate: dto.endDate,
  grundDerBeherbergung: "", // Default or based on logic
  berechnungsartDerCityTax: "", // Default or based on logic
  kurTaxeBerechnen: false, // Default or based on logic
  propertyId: '', // If applicable
  customerId: '', // If applicable
  specialEquipment: specialEquipments.filter(eq => dto.specialEquipment.includes(eq.id)) // Populate based on provided IDs
});
