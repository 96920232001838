import { Box, Container, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import BookingsTable from '../components/data-display/BookingsTable';
import { useData } from '../contexts/DataContext'; // Update the path as per your structure

import { useAuth } from '../contexts/AuthContext'; // Adjust the path as per your structure
import { Booking } from '../model/RoomBooking'; // Adjust import path as needed
import BirthdayTable from '../components/data-display/BirthdayTable';


const HomePage: React.FC = () => {
    const { bookings } = useData(); // Fetch bookings from your data context
    const [ongoingBookings, setOngoingBookings] = useState<Booking[]>([]);
    const [upcomingBookings, setUpcomingBookings] = useState<Booking[]>([]);
    const [pastBookings, setPastBookings] = useState<Booking[]>([]);
    const { user } = useAuth(); // Access user from context

    useEffect(() => {
        const now = dayjs();
        setOngoingBookings(bookings.filter(booking => now.isBetween(dayjs(booking.startDate), dayjs(booking.endDate))));
        setUpcomingBookings(bookings.filter(booking => now.isBefore(dayjs(booking.startDate))));
        setPastBookings(bookings.filter(booking => now.isAfter(dayjs(booking.endDate))));
    }, [bookings]);

    return (
        <Container maxWidth="lg">
            {user && <Typography variant="h5" gutterBottom>Welcome, {user.email}!</Typography>}
            <BirthdayTable />
            
            <Box sx={{ mb: 3 }}>
                <Typography variant="h4" gutterBottom>Ongoing Bookings</Typography>
                <Paper elevation={3}>
                    <BookingsTable bookings={ongoingBookings} sortField={'endDate'}/>
                </Paper>
            </Box>

            <Box sx={{ mb: 3 }}>

                <Typography variant="h4" gutterBottom>Upcoming Bookings</Typography>
                <Paper elevation={3}>
                    <BookingsTable bookings={upcomingBookings} sortField={'startDate'}/>
                </Paper>
            </Box>

            <Box sx={{ mb: 3 }}>

                <Typography variant="h4" gutterBottom>Past Bookings</Typography>
                <Paper elevation={3}>
                    <BookingsTable bookings={pastBookings} sortField={'endDate'} />
                </Paper>
            </Box>

        </Container>
    );
};

export default HomePage;
