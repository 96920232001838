import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import React from 'react';
import '../../../styles/App.css';

interface PinnedColumnsGridProps {
    rows: GridRowModel[];
    pinnedColumns: GridColDef[];
    otherColumns: GridColDef[];
    onRowClick?: (params: any) => void;
    onRowDoubleClick?: (params: any) => void;
}

const PinnedColumnsGrid: React.FC<PinnedColumnsGridProps> = ({
    rows,
    pinnedColumns,
    otherColumns,
    onRowClick,
    onRowDoubleClick,
}) => {
    return (
        <Box className="dataGridContainer">
            <Box className="pinnedAndScrollableGrids">
                {/* Pinned Columns DataGrid */}
                <Box className="pinnedGrid">
                    <DataGrid
                        rows={rows}
                        columns={pinnedColumns}
                        hideFooter
                        onRowClick={onRowClick}
                        onRowDoubleClick={onRowDoubleClick}
                    />
                </Box>

                {/* Main DataGrid */}
                <Box className="scrollableGrid">
                    <DataGrid
                        rows={rows}
                        columns={otherColumns}
                        hideFooter
                        onRowClick={onRowClick}
                        onRowDoubleClick={onRowDoubleClick}
                    />
                </Box>
            </Box>
        </Box>
    );
};


export default PinnedColumnsGrid;
