import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useData } from '../../contexts/DataContext';
import { Room, SpecialEquipment } from '../../model/RoomBooking';
import { getPropertyPrice } from '../../resources/PropertyPrices';

interface EditRoomFormProps {
  room: Room | null;
  open: boolean;
  onClose: () => void;
  onSave: (room: Room) => void;
}

const emptyRoom: Room = {
  id: '',
  roomId: -1,
  location: '',
  address: '',
  roomType: '',
  numberOfPeople: 0,
  nextCleaning: '',
  nextMattressChange: '',
  nextGasDelivery: '',
  price: 0,
  specialEquipment: [],
  bookings: [],
  ownerId: ''
};

const roomTypes = [
  'Einzelzimmer',
  'Doppelzimmer',
  'Dreibettzimmer',
  'Ferienwohnung',
  'Suite',
  'DZ Einzelnutzung'
];

const EditRoomForm: React.FC<EditRoomFormProps> = ({ room, open, onClose, onSave }) => {
  const { equipment } = useData();
  const [editedRoom, setEditedRoom] = useState<Room>(emptyRoom);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedRoom(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    onSave(editedRoom);
    onClose();
  };


  const handleEquipmentChange = (equipmentItem: SpecialEquipment, checked: boolean) => {
    setEditedRoom(prev => ({
      ...prev,
      specialEquipment: checked
        ? [...prev.specialEquipment, equipmentItem]
        : prev.specialEquipment.filter(item => item.id !== equipmentItem.id)
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{room ? 'Edit Room' : 'Add New Room'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="location"
          label="Location"
          type="text"
          fullWidth
          name="location"
          value={editedRoom.location}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="address"
          label="Address"
          type="text"
          fullWidth
          name="address"
          value={editedRoom.address}
          onChange={handleChange}
        />
        <Autocomplete
          fullWidth
          id="roomType"
          options={roomTypes}
          value={editedRoom.roomType}
          onChange={(event: any, newValue: string | null) => {
            const selectedRoomType = newValue;
            const selectedRoomPrice = getPropertyPrice(selectedRoomType); // Get the price based on the selected room type

            setEditedRoom(prev => ({ ...prev, roomType: newValue || '', price: selectedRoomPrice }));
          }}
          renderInput={(params) => <TextField {...params} label="Room Type" />}
        />
        <TextField
          margin="dense"
          id="numberOfPeople"
          label="Number Of People"
          type="number"
          fullWidth
          name="numberOfPeople"
          value={editedRoom.numberOfPeople}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="nextCleaning"
          label="Next Cleaning Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="nextCleaning"
          value={editedRoom.nextCleaning}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="nextMattressChange"
          label="Next Mattress Change Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="nextMattressChange"
          value={editedRoom.nextMattressChange}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="nextGasDelivery"
          label="Next Gas Delivery Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="nextGasDelivery"
          value={editedRoom.nextGasDelivery}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          id="price"
          label="Price"
          type="number"
          fullWidth
          name="price"
          value={editedRoom.price}
          onChange={handleChange}
        />
       {equipment.map((equipmentItem: SpecialEquipment) => (
          <FormControlLabel
            key={equipmentItem.id}
            control={
              <Checkbox
                checked={editedRoom.specialEquipment?.some(e => e.id === equipmentItem.id)}
                onChange={(event) => handleEquipmentChange(equipmentItem, event.target.checked)}
                name={equipmentItem.name}
              />
            }
            label={equipmentItem.name}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRoomForm;
