import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import RowDetails from '../components/data-display/ClientDetailsPanel';
import EditRowForm from '../components/forms/ClientForm';
import { useData } from '../contexts/DataContext';
import { Client } from '../model/RoomBooking';
import de from '../resources/locales/local-de';
import '../styles/App.css';

const columns: GridColDef[] = [
  {
    field: 'bookings', headerName: de.common.bookings, width: 130, renderCell: (params) => {
      if (Array.isArray(params.value)) {
        return <span>{params.value.map((booking: any) => booking.bun).join(', ')}</span>;
      }
      return <span>{de.common.noBookingsFound}</span>;
    }
  },
  { field: 'kdNr', headerName: de.clientDetails.customerNumber, width: 100 },
  { field: 'anrede', headerName: de.clientDetails.salutation, width: 100 },
  { field: 'name', headerName: de.clientDetails.name, width: 130 },
  { field: 'strasse', headerName: de.clientDetails.street, width: 150 },
  { field: 'wohnort', headerName: de.clientDetails.residence, width: 130 },
  { field: 'land', headerName: de.clientDetails.country, width: 120 },
  { field: 'geburstag', headerName: de.clientDetails.birthday, width: 130 },
  { field: 'naechsteWerbung', headerName: de.clientDetails.nextAdvertisement, width: 160 },
  { field: 'standortStadt', headerName: de.clientDetails.locationCity, width: 160 },
  { field: 'objektAnschrift', headerName: de.clientDetails.objectAddress, width: 180 },
  { field: 'benutzername', headerName: de.clientDetails.username, width: 150 },
  { field: 'telefon', headerName: de.clientDetails.phone, width: 130 },
  { field: 'handy', headerName: de.clientDetails.mobile, width: 130 },
];

const Uebersichtstafel: React.FC = () => {
  const { clients, addEntity } = useData();
  const [rows, setRows] = useState<Client[]>([]);
  const [selectedRow, setSelectedRow] = useState<Client | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    setRows(clients);
  }, [clients]);

  useEffect(() => {
    if (selectedRow !== null) {
      const updatedSelectedRow = rows.find(row => row.id === selectedRow.id);
      setSelectedRow(updatedSelectedRow || null);
    }
  }, [rows, selectedRow]);

  const handleRowClick = (params: any) => {
    setIsEditModalOpen(true);
  };

  const handleEditOpen = () => setIsEditModalOpen(true);
  const handleEditClose = () => setIsEditModalOpen(false);

  const handleSaveEditedRow = (editedRow: Client) => {
    if (editedRow.id === '') {
      setRows([...rows, { ...editedRow }]);
      addEntity('client',{ ...editedRow }); 
    } else {
      // updateClient(editedRow);
      setRows(rows.map(row => (row.id === editedRow.id ? editedRow : row)));
    }
    handleEditClose();
  };

  return (
    <div>
      <Button variant="contained" onClick={() => { setSelectedRow(null); handleEditOpen(); }}>
        {de.common.addNew}
      </Button>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          onRowClick={(params) => setSelectedRow(params.row)}
          onCellDoubleClick={handleRowClick}
        />
      </div>
      {selectedRow && (<div>
        <Button variant="contained" onClick={() => { setSelectedRow(selectedRow); handleEditOpen(); }}>
          {de.common.edit}
        </Button>
        <RowDetails row={selectedRow}></RowDetails>
      </div>
      )}
      <EditRowForm
        row={selectedRow}
        open={isEditModalOpen}
        onClose={handleEditClose}
        onSave={handleSaveEditedRow}
      />
    </div>
  );
};

export default Uebersichtstafel;
